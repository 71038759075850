import React from 'react';

const YourQuery = () => {
	return (
		<div className='container vh-100 d-flex flex-column justify-content-center'>
			<h3 className='h1 text-center' style={{ color: '#003056' }}>
				Your Query{' '}
			</h3>
			<h1 className='display-3 text-center'>
				Design Is not Present in Assigned design.
			</h1>
		</div>
	);
};

export default YourQuery;
