import React from 'react';
import './Banner.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Banner = ({ title, bgImg, para }) => {
	return (
		<div className='position-relative p-0 banner-div'>
			<LazyLoadImage src={bgImg} alt='banner' />
			<div className='banner-overlay'>
				<h2
					style={{
						color: '#fff',
						letterSpacing: '5px',
						textTransform: 'uppercase',
						fontFamily: 'Poppins',
						fontWeight: '700',
					}}
					className='display-5'
				>
					{title}
				</h2>
				<h5 className='banner-h4' style={{ color: 'white', textAlign: 'center' }}>
					{para}
				</h5>
			</div>
		</div>
	);
};

export default Banner;
