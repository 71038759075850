import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import clientImage from '../assets/team/1.png';

import { Pagination } from 'swiper/modules';
import HeadingComponent from './headingComponent/HeadingComponent';

const Testimonials = () => {
	return (
		<div className='container-fluid bg-white' style={{ marginTop: '-40px' }}>
			<HeadingComponent
				headerName={"Empowering Tomorrow's Sunshine: Our Solar Stories"}
			/>

			<Swiper
				slidesPerView={2}
				spaceBetween={20}
				autoplay={{
					delay: 2000,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					359: {
						slidesPerView: 1,
						spaceBetween: 50,
					},
					390: {
						slidesPerView: 1,
						spaceBetween: 50,
					},
					768: {
						slidesPerView: 1,
						spaceBetween: 40,
					},
					1024: {
						slidesPerView: 2,
						spaceBetween: 50,
					},
				}}
				modules={[Pagination]}
				className='mySwiper swiper_padding '
			>
				<SwiperSlide>
					<div className='main-container'>
						<div className='swiper-client-msg'>
							<p>
								<b style={{ color: '#003056' }} s>
									Eco-Conscious and Happy
								</b>
								<br /> Choosing Sunruf for my solar installation was one of the best
								decisions I've made for my family and the environment. Their team guided
								me through the entire process, making it easy to understand and
								hassle-free. The solar panels have been performing exceptionally well,
								and it's satisfying to see my electricity meter spinning backward. With
								Sunruf, I've not only gone green, but I'm also enjoying reliable and
								clean energy. Their 5-star rating is well-deserved!
							</p>
						</div>
						<div className='container mb-5 swiper-client-data'>
							<div className='row mt-5 d-flex align-items-center '>
								<div className='col m-0'>
									<figure>
										<img src={clientImage} alt='clientReviewImage' />
									</figure>
								</div>
								<div className='col m-0'>
									<div className='client-data-details'>
										<p>Mrs. Meena Shah</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<div className='swiper-client-msg'>
							<p>
								<b style={{ color: '#003056' }}>Commercial Solar Success</b> <br />
								As a business owner, I was looking for ways to reduce overhead costs and
								contribute to a greener planet. Sunruf provided the perfect solution
								with their solar expertise. They designed and installed a top-notch
								solar system for my commercial property, which has had a remarkable
								impact on our energy expenses. Their commitment to excellence and their
								5-star service rating are truly impressive. Sunruf, you've helped my
								business thrive while making a positive environmental impact.
							</p>
						</div>
						<div className='container mb-5 swiper-client-data'>
							<div className='row mt-5 d-flex align-items-center '>
								<div className='col m-0'>
									<figure>
										<img src={clientImage} alt='clientReviewImage' />
									</figure>
								</div>
								<div className='col m-0'>
									<div className='client-data-details'>
										<p>Mr. Rajendra Singh</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<div className='swiper-client-msg'>
							<p>
								<b style={{ color: '#003056' }}>Beyond Expectations</b> <br />
								When I decided to switch to solar with Sunruf, I had high expectations.
								I'm thrilled to say that they exceeded every one of them. Their
								attention to detail during the design and installation process was
								remarkable, and the quality of their work is evident in the performance
								of my solar panels. The cost savings are significant, and I'm proud to
								be a part of the solar energy movement. Sunruf's 5-star rating is
								well-earned, and I couldn't be happier with the results.
							</p>
						</div>
						<div className='container mb-5 swiper-client-data'>
							<div className='row mt-5 d-flex align-items-center '>
								<div className='col m-0'>
									<figure>
										<img src={clientImage} alt='clientReviewImage' />
									</figure>
								</div>
								<div className='col m-0'>
									<div className='client-data-details'>
										<p>Dr. Kamlesh Timari</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<div className='swiper-client-msg'>
							<p>
								<b style={{ color: '#003056' }}>A Bright Investment</b> <br />
								Investing in solar energy with Sunruf was one of the best decisions I've
								made for my home and my family's future. From day one, their team
								provided clear information, answered all my questions, and delivered a
								top-tier solar system. I've seen a substantial reduction in my energy
								bills, and I'm doing my part to reduce carbon emissions. Sunruf's
								expertise and customer service have been exceptional, and I
								wholeheartedly recommend them to anyone considering a switch to solar.
								Their 5-star rating speaks volumes about their commitment to excellence.
							</p>
						</div>
						<div className='container mb-5 swiper-client-data'>
							<div className='row mt-5 d-flex align-items-center '>
								<div className='col m-0'>
									<figure>
										<img src={clientImage} alt='clientReviewImage' />
									</figure>
								</div>
								<div className='col m-0'>
									<div className='client-data-details'>
										<p>Dr. Kamlesh Timari</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='main-container'>
						<div className='swiper-client-msg'>
							<p>
								<b style={{ color: '#003056' }}>A Satisfied Homeowner</b> <br />I can't
								express how thrilled I am with my decision to switch to solar with
								Sunruf. From the moment I contacted them for a quote to the day my solar
								panels were installed, their professionalism and expertise were evident.
								They provided me with a cost-effective solar solution tailored to my
								needs, and the installation process was seamless. Now, I'm not only
								reducing my carbon footprint but also enjoying significant savings on my
								energy bills. Thanks, Sunruf, for helping me make a positive change for
								the environment and my wallet!
							</p>
						</div>
						<div className='container mb-5 swiper-client-data'>
							<div className='row mt-5 d-flex align-items-center '>
								<div className='col m-0'>
									<figure>
										<img src={clientImage} alt='clientReviewImage' />
									</figure>
								</div>
								<div className='col m-0'>
									<div className='client-data-details'>
										<p>Mr. Yash Moitra</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
};

export default Testimonials;
