import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppsIcon from '@mui/icons-material/Apps';

import './Navbar.css';

import img1 from '../../assets/searchbymenu/11.png';
import img2 from '../../assets/searchbymenu/2.png';
import img3 from '../../assets/searchbymenu/3.png';
import img4 from '../../assets/searchbymenu/4.png';
import img5 from '../../assets/searchbymenu/5.png';
import img6 from '../../assets/searchbymenu/6.png';
import img7 from '../../assets/searchbymenu/7.png';
import img8 from '../../assets/searchbymenu/8.png';
import img9 from '../../assets/searchbymenu/1.png';

import { Link } from 'react-router-dom';
import SignIn from '../../pages/SignIn';
import CenterLocator from '../searchByMenuPages/CenterLocator';
import SolarEntrepreneur from '../searchByMenuPages/SolarEntrepreneur';
import SolarExpert from '../searchByMenuPages/SolarExpert';
import RoofTop from '../searchByMenuPages/RoofTop';
import SiteSurvey from '../searchByMenuPages/SiteSurvey';

const style = {
	position: 'absolute',
	top: '55%',
	left: '49%',
	transform: 'translate(-50%, -47%)',
	width: 575,
	height: 480,
	bgcolor: '#003056',
	boxShadow: 24,
	p: 4,
};

const Mobilestyle = {
	position: 'absolute',
	top: '55%',
	left: '50%',
	transform: 'translate(-50%, -47%)',
	width: 400,
	height: 480,
	bgcolor: '#003056',
	border: '2px solid #000',
	boxShadow: 24,
	p: 5,
};

export default function TransitionsModal() {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const clickOnModalIcon = () => {
		setOpen(!open);
	};

	return (
		<div>
			<Button onClick={handleOpen} style={{ width: '50%' }}>
				<AppsIcon style={{ color: '#fff', fontSize: '32px' }} />
			</Button>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style} xs={Mobilestyle}>
						<Typography
							id='transition-modal-title'
							variant='h6'
							component='h2'
							className='text-center text-white fw-bold '
						>
							Menu
						</Typography>
						<Typography id='transition-modal-description' sx={{ mt: 1 }}>
							<div className='container-fluid'>
								<div className='col col-md my-2'>
									<div className='row modalrow row-cols-3'>
										<div className='col border py-2'>
											<Link to='/survey' onClick={() => clickOnModalIcon()}>
												<img
													src={img1}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>Solar Calculator</h6>
											</Link>
										</div>
										<div className='col border py-2 '>
											<Link
												to='/survey'
												element={<SiteSurvey />}
												onClick={() => clickOnModalIcon()}
											>
												<img
													src={img2}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>Book a Solar Site Survey</h6>
											</Link>
										</div>
										<div className='col-md-4 border py-2'>
											<Link
												to='https://solarrooftop.gov.in'
												target='_blank'
												onClick={() => clickOnModalIcon()}
											>
												<img
													src={img3}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>Rooftop Solar Subsidy</h6>
											</Link>
										</div>
									</div>
								</div>
								<div className='col-md-12 my-2'>
									<div className='row modalrow row-cols-3'>
										<div className='col-md-4 border py-2'>
											<Link to='/solarMart' onClick={() => clickOnModalIcon()}>
												<img
													src={img4}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>Solar Mart</h6>
											</Link>
										</div>
										<div className='col-md-4 border py-2'>
											<Link
												to='/rooftop'
												element={<RoofTop />}
												onClick={() => clickOnModalIcon()}
											>
												<img
													src={img5}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>Rooftop Solar Solution</h6>
											</Link>
										</div>
										<div className='col-md-4 border py-2'>
											<Link
												to='/solarExpert'
												element={<SolarExpert />}
												onClick={() => clickOnModalIcon()}
											>
												<img
													src={img6}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>Ask Solar Expert</h6>
											</Link>
										</div>
									</div>
								</div>
								<div className='col-md-12 my-2'>
									<div className='row modalrow row-cols-3 row-cols-2'>
										<div className='col-md-4 border py-2'>
											<Link
												to='/solarEntrepreneur'
												element={<SolarEntrepreneur />}
												onClick={() => clickOnModalIcon()}
											>
												<img
													src={img7}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>Be A Solar Entrepreneur</h6>
											</Link>
										</div>
										<div className='col-md-4 border py-2'>
											<Link
												to='/centerLocator'
												element={<CenterLocator />}
												onClick={() => clickOnModalIcon()}
											>
												<img
													src={img8}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>Solar Experience Center Locator</h6>
											</Link>
										</div>
										<div className='col-md-4 border py-2'>
											<Link
												to='/signin'
												element={<SignIn />}
												onClick={() => clickOnModalIcon()}
											>
												<img
													src={img9}
													alt=''
													style={{ width: '50px', display: 'block', margin: '10px auto' }}
												/>
												<h6 className='navModal_h6'>My Account</h6>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</Typography>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}
