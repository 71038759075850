import React from 'react';

import { Link } from 'react-router-dom';

import img1 from '../assets/searchbymenu/11.png';
import img2 from '../assets/searchbymenu/2.png';
import img3 from '../assets/searchbymenu/3.png';
import img4 from '../assets/searchbymenu/4.png';
import img5 from '../assets/searchbymenu/5.png';
import img6 from '../assets/searchbymenu/6.png';
import img7 from '../assets/searchbymenu/7.png';
import img8 from '../assets/searchbymenu/8.png';
import SolarExpert from './searchByMenuPages/SolarExpert';
import CenterLocator from './searchByMenuPages/CenterLocator';
import SolarEntrepreneur from './searchByMenuPages/SolarEntrepreneur';
import RoofTop from './searchByMenuPages/RoofTop';

import HeadingComponent from '../components/headingComponent/HeadingComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SearchByMenu = () => {
	const goToTopBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	};

	return (
		<div className='container-fluid search_by_menu_container mb-5'>
			<HeadingComponent headerName={'Search By Menu'} />

			<div className='searchByMenu'>
				<div className='slideWrapper'>
					<Link to='/survey'>
						<LazyLoadImage
							src={img1}
							alt=''
							height='100px'
							onClick={goToTopBtn}
							style={{ margin: '0px auto' }}
						/>
						<h6 className='text-center my-2 search_by_menu_title'>
							Solar Calculator
						</h6>
					</Link>
				</div>
				<div className='slideWrapper'>
					<Link to='/survey'>
						<LazyLoadImage
							src={img2}
							alt=''
							height='100px'
							onClick={goToTopBtn}
							style={{ margin: '0 auto' }}
						/>
						<h6 className='text-center my-2 search_by_menu_title'>
							Book Free Solar Site Survey
						</h6>
					</Link>
				</div>
				<div className='slideWrapper'>
					<Link to='https://solarrooftop.gov.in' target='_blank'>
						<LazyLoadImage
							src={img3}
							alt=''
							height='100px'
							onClick={goToTopBtn}
							style={{ margin: '0 auto' }}
						/>
						<h6 className='text-center my-2 search_by_menu_title'>
							Rooftop Solar Subsidy
						</h6>
					</Link>
				</div>
				<div className='slideWrapper'>
					<Link to='/solarMart'>
						<LazyLoadImage
							src={img4}
							alt=''
							height='100px'
							onClick={goToTopBtn}
							style={{ margin: '0 auto' }}
						/>
						<h6 className='text-center my-2 search_by_menu_title'>Solar Mart</h6>
					</Link>
				</div>
				<div className='slideWrapper'>
					<Link to='/rooftop' element={<RoofTop />}>
						<LazyLoadImage
							src={img5}
							alt=''
							height='100px'
							onClick={goToTopBtn}
							style={{ margin: '0 auto' }}
						/>
						<h6 className='text-center my-2 search_by_menu_title'>
							Rooftop Solar Solution
						</h6>
					</Link>
				</div>
				<div className='slideWrapper'>
					<Link to='/solarExpert' element={<SolarExpert />}>
						<LazyLoadImage
							src={img6}
							alt=''
							height='100px'
							onClick={goToTopBtn}
							style={{ margin: '0 auto' }}
						/>
						<h6 className='text-center my-2 search_by_menu_title'>
							Ask Solar Expert
						</h6>
					</Link>
				</div>
				<div className='slideWrapper'>
					<Link to='/solarEntrepreneur' element={<SolarEntrepreneur />}>
						<LazyLoadImage
							src={img7}
							alt=''
							height='100px'
							onClick={goToTopBtn}
							style={{ margin: '0 auto' }}
						/>
						<h6 className='text-center my-2 search_by_menu_title'>
							Be A Solar Entrepreneur
						</h6>
					</Link>
				</div>
				<div className='slideWrapper'>
					<Link to='/centerLocator' element={<CenterLocator />}>
						<LazyLoadImage
							src={img8}
							alt=''
							height='100px'
							onClick={goToTopBtn}
							style={{ margin: '0 auto' }}
						/>
						<h6 className='text-center my-2 search_by_menu_title'>
							Solar Experience Centre Locator
						</h6>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SearchByMenu;
