import React from 'react';
import './Herosection.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import usp2 from '../../assets/solarrooftopsolutions/11.png';
import usp0 from '../../assets/solarrooftopsolutions/9.png';
import usp1 from '../../assets/solarrooftopsolutions/10.png';

import { Link } from 'react-router-dom';
import image1 from '../../assets/banner/main_banner.jpg';
import SiteSurvey from '../searchByMenuPages/SiteSurvey';

const Herosection = () => {
	return (
		<section>
			<div class='container-fluid p-0'>
				<div className='header-top p-0'>
					<div class='cardx p-0 m-0 position-relative'>
						<LazyLoadImage src={image1} class='card-imgx' alt='' />
						<div
							class='card-img-overlay'
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<h1
								class='card-title display-5'
								style={{
									fontWeight: '700',
									fontFamily: 'Poppins',
									textAlign: 'center',
								}}
							>
								ZERO YOUR ELECTRICITY BILL GUARRANTED!
							</h1>
							<p
								class='card-text'
								style={{
									fontSize: '22px',
									color: '#fff',
									fontWeight: '500',
									letterSpacing: '1px',
									wordSpacing: '2px',
								}}
							>
								Home | Housing Society | Commerical | Industrial | EV Charging Station
							</p>
							<div class='gap-2 col-md-3 d-grid col-sm-3 mx-auto'>
								<button
									class='btn'
									type='button'
									style={{
										backgroundColor: '#003056',
										color: 'white',
										padding: '1rem',
									}}
								>
									<Link to='/survey' element={<SiteSurvey />}>
										Get A Quote
									</Link>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className='header-bottom'>
					<div class='card-group gap-0'>
						<div
							class='card p-4'
							style={{
								background: '#003056',
								textAlign: 'center',
							}}
						>
							<img
								src={usp0}
								alt=''
								style={{
									color: '#fff',
									height: '150px',
									width: '150px',
									marginInline: 'auto',
								}}
							/>
							<div class='card-body'>
								<h5 class='card-title'>Affordable Price</h5>
							</div>
						</div>
						<div
							class='card p-4'
							style={{
								background: '#003056',
								textAlign: 'center',
							}}
						>
							<img
								src={usp1}
								alt=''
								style={{
									color: '#fff',
									height: '150px',
									width: '150px',
									marginInline: 'auto',
								}}
							/>
							<div class='card-body'>
								<h5 class='card-title'>Upto 27 Years of Performance Warranty</h5>
							</div>
						</div>
						<div
							class='card p-4'
							style={{
								background: '#003056',
								textAlign: 'center',
							}}
						>
							<img
								src={usp2}
								alt=''
								style={{
									color: '#fff',
									height: '150px',
									width: '150px',
									marginInline: 'auto',
								}}
							/>

							<div class='card-body'>
								<h5 class='card-title'>
									Trusted by No. of Rooftop Solar owner in Bihar
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Herosection;
