import React from 'react';
import img1 from '../../assets/team/1.png';
import './Profile.css';
import { Link, useNavigate } from 'react-router-dom';

const Profile = () => {
	const onLogout = () => {
		// auth.signOut();
		navigate('/');
	};

	const navigate = useNavigate();

	return (
		<div
			className='container profile_container vh-100'
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<div
				class='card mb-3'
				style={{
					maxWidth: 'auto',
					// height: '310px',
					maxHeight: 'auto',
					background: '#003056',
					// marginBlock: '125px',
				}}
			>
				<div class='row profile-details g-0'>
					<div class='col-md-6'>
						<img src={img1} class='img-fluid rounded-start profile_img' alt='...' />
					</div>
					<div class='col-md-6 p-0 m-auto'>
						<div class='card-body'>
							<h4 class='card-title'>User Name : Abhishek Singh</h4>
							<h4 class='card-title'>Mobile Number : +91 1234567890</h4>
							<h5 class='card-title'>User ID Address : Consumer Mobile Number</h5>
						</div>
					</div>
				</div>
			</div>
			<div class='dropdown'>
				<a
					class='btn btn-secondary dropdown-toggle'
					href='!#'
					role='button'
					id='dropdownMenuLink'
					data-bs-toggle='dropdown'
					aria-expanded='false'
				>
					Profile Page Menus
				</a>

				<ul className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
					<Link to='/editProfile'>
						<a href='#!' class='dropdown-item'>
							Edit Profile
						</a>
					</Link>
					<Link to='/yourEnquiry'>
						<a href='#!' class='dropdown-item'>
							Your Enquiries
						</a>
					</Link>
					<Link to='/refer'>
						<a href='#!' class='dropdown-item'>
							Refer & Earn
						</a>
					</Link>
					<Link to='/'>
						<a href='#!' class='dropdown-item' onClick={onLogout}>
							Logout
						</a>
					</Link>
				</ul>
			</div>
		</div>
	);
};

export default Profile;
