import React from 'react';
import './WhySunruf.css';

import About from '../../pages/about/About';

import img1 from '../../assets/banner/0.png';
import { Link } from 'react-router-dom';

const WhySunruf = () => {
	const goToTopBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	};

	return (
		<div className='container-fluid p-0'>
			{/* <HeadingComponent headerName={'Why Sunruf'} /> */}
			<div class='card bg-dark text-white why_sunruf_div'>
				<img src={img1} class='card-img' alt='WhySunrufImage' />
				<div class='card-img-overlay why_sunruf'>
					<h2 class='card-title'>SUNRUF: SWITCH TO SOLAR</h2>
					<p class='card-text'>
						Green Energy <br /> An initiative for a better tomorrow!
					</p>
					<ul class='sunruf-points' style={{ color: 'white' }}>
						<li>Reduces your Electricity Bill</li>
						<li>Sustainable &amp; Clean Energy</li>
						<li>Risk Free Investment</li>
						<li>Better Return on Investment</li>
					</ul>
					<button
						style={{
							display: 'flex',
							padding: '10px 20px',
							background: '#003056',
							color: '#fff',
						}}
					>
						<Link
							to='/about'
							element={<About />}
							onClick={() => {
								goToTopBtn();
							}}
						>
							Learn More
						</Link>
					</button>
				</div>
			</div>
		</div>
	);
};

export default WhySunruf;
