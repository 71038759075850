import React from 'react';
import Banner from '../banner/Banner';
import img1 from '../../assets/banner/b2.jpg';
import './SearchByMenuPages.css';

const CenterLocator = () => {
	const [selectedDistrict, setSelectedDistrict] = React.useState('');
	const handleChange = (e) => {
		setSelectedDistrict(e.target.value);
	};

	const districtOptions = [
		'Araria',
		'Arwal',
		'Aurangabad',
		'Banka',
		'Begusarai',
		'Bhagalpur',
		'Bhojpur',
		'Buxar',
		'Darbhanga',
		'East Champaran',
		'Gaya',
		'Gopalganj',
		'Jamui',
		'Jehanabad',
		'Kaimur',
		'Katihar',
		'Khagaria',
		'Kishanganj',
		'Lakhisarai',
		'Madhepura',
		'Madhubani',
		'Munger',
		'Muzaffarpur',
		'Nalanda',
		'Nawada',
		'Patna',
		'Purnia',
		'Rohtas',
		'Saharsa',
		'Samastipur',
		'Saran',
		'Sheikhpura',
		'Sheohar',
		'Sitamarhi',
		'Siwan',
		'Supaul',
		'Vaishali',
		'West Champaran',
	];

	return (
		<div>
			<Banner bgImg={img1} title={'Welcome to Solar Experience Center'} />
			<div className='container my-5'>
				<div class='row row-cols-1 row-cols-md-2 g-4'>
					<div class='col'>
						<div class='card'>
							<img src={img1} class='card-img-top' alt='...' />
							<div class='card-body'>
								<h5 class='card-title' style={{ color: '#003056' }}>
									Solar Experience Centre
								</h5>{' '}
								<p>
									<strong>Contact Person:</strong> Vivek Saurav
								</p>
								<p class='card-text'>
									{' '}
									<strong>Contact Number:</strong> +91 82108 19658
								</p>
								<p class='card-text'>
									{' '}
									<strong>Address:</strong> Sahu Colony, Gulzarbagh, Meena Bazar, Patna,
									Bihar 800007.
								</p>
							</div>
						</div>
					</div>
					<div class='col'>
						<div class='card'>
							<img src={img1} class='card-img-top' alt='...' />
							<div class='card-body'>
								<h5 class='card-title' style={{ color: '#003056' }}>
									Solar Experience Centre
								</h5>
								<p class='card-text'>
									{' '}
									<strong>Contact Person:</strong> Abhishek Singh
								</p>
								<p class='card-text'>
									{' '}
									<strong>Contact Number:</strong> +91 83407 40973
								</p>
								<p class='card-text'>
									{' '}
									<strong>Address:</strong> Plot No. 4 Ashiyana Nagar Sec-2 Ramnagri
									Patna 800025.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container mb-5 p-3 border'>
				<h3>Nearby Solar Experience Center</h3>
				<div className='row'>
					<div className='col-md-6'>
						<div class='mb-3'>
							<label for='exampleFormControlInput1' class='form-label'>
								City / District / Pincode <b style={{ color: 'red' }}>*</b>
							</label>
							<div>
								<select
									value={selectedDistrict}
									onChange={handleChange}
									className='form-select'
								>
									<option value=''>Select a district</option>
									{districtOptions.map((district) => (
										<option key={district} value={district}>
											{district}
										</option>
									))}
								</select>
								{/* {selectedDistrict && <p>You selected: {selectedDistrict}</p>} */}
							</div>
						</div>
						<div>
							<select class='form-select' aria-label='Default select example'>
								<option selected>Select State</option>
								<option value='Bihar'>Bihar</option>
							</select>
						</div>
					</div>
					<div className='col-md-6 text-center mt-3'>
						<button
							style={{ background: '#003056', color: 'white', padding: '10px 70px' }}
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CenterLocator;
