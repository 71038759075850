import React from 'react';
import { Link } from 'react-router-dom';

import HeadingComponent from './headingComponent/HeadingComponent';
import img1 from '../assets/knowledgeCenter/1.jpg';
import img2 from '../assets/knowledgeCenter/3.jpg';
import img3 from '../assets/knowledgeCenter/5.jpg';
import img4 from '../assets/knowledgeCenter/4.jpg';
import KnowledgePage from './knowledgePage/KnowledgePage';

const KnowledgeCenter = ({ setKnowledgeID }) => {
	const goToTopBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	};
	return (
		<div>
			<div class='container-fluid album'>
				<div class='container-fluid'>
					<HeadingComponent headerName={'Knowledge Center'} />
					<div class='row row-cols-1 row-cols-sm-2 row-cols-md-4'>
						<div class='col mb-3'>
							<div class='card shadow-sm'>
								<img
									src={img1}
									alt=''
									style={{
										height: '180px',
										width: '100%',
										objectFit: 'cover',
									}}
								/>
								<div
									class='card-body'
									style={{
										height: '180px',
										display: 'flex',
										flexDirection: 'column',
										margin: 'auto',
										justifyContent: 'center',
									}}
								>
									<h5 class='card-text my-2'>
										What are the Types of Solar Power Systems?
									</h5>
									<h6 class='card-text'>If you’re hunting for a great ...</h6>
									<div class='my-2'>
										<div class='btn-group'>
											<button
												type='button'
												style={{
													background: '#003056',
													color: 'white',
													textDecoration: 'none',
												}}
												class='btn btn-sm btn-outline-dark'
											>
												<Link
													to='/knowledge'
													element={<KnowledgePage />}
													onClick={() => {
														goToTopBtn();
														setKnowledgeID(1);
													}}
												>
													Read More
												</Link>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class='col mb-3'>
							<div class='card shadow-sm'>
								<img
									src={img2}
									alt=''
									style={{
										height: '180px',
										width: '100%',
										objectFit: 'cover',
									}}
								/>
								<div
									class='card-body'
									style={{
										height: '180px',
										display: 'flex',
										flexDirection: 'column',
										margin: 'auto',
										justifyContent: 'center',
									}}
								>
									<h5 class='card-text'>What are the Types of Solar Panels?</h5>
									<h6 class='card-text'>The main types of solar...</h6>
									<div class='my-2'>
										<div class='btn-group'>
											<button
												type='button'
												style={{
													background: '#003056',
													color: 'white',
													textDecoration: 'none',
												}}
												class='btn btn-sm btn-outline-dark'
											>
												<Link
													to='/knowledge'
													element={<KnowledgePage />}
													onClick={() => {
														goToTopBtn();
														setKnowledgeID(2);
													}}
												>
													Read More
												</Link>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class='col mb-3'>
							<div class='card shadow-sm'>
								<img
									src={img3}
									alt=''
									style={{
										height: '180px',
										width: '100%',
										objectFit: 'cover',
									}}
								/>
								<div
									class='card-body'
									style={{
										height: '180px',
										display: 'flex',
										flexDirection: 'column',
										margin: 'auto',
										justifyContent: 'center',
									}}
								>
									<h5 class='card-text'>
										How Solar Can Save My Monthly Electricity Bills?
									</h5>
									<h6 class='card-text'>Switching to solar energy...</h6>
									<div class='my-2'>
										<div class='btn-group'>
											<button
												type='button'
												style={{
													background: '#003056',
													color: 'white',
													textDecoration: 'none',
												}}
												class='btn btn-sm btn-outline-dark'
											>
												<Link
													to='/knowledge'
													element={<KnowledgePage />}
													onClick={() => {
														goToTopBtn();
														setKnowledgeID(3);
													}}
												>
													Read More
												</Link>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class='col mb-3'>
							<div class='card shadow-sm'>
								<img
									src={img4}
									alt=''
									style={{
										height: '180px',
										width: '100%',
										objectFit: 'cover',
									}}
								/>
								<div
									class='card-body'
									style={{
										height: '180px',
										display: 'flex',
										flexDirection: 'column',
										margin: 'auto',
										justifyContent: 'center',
									}}
								>
									<h5 class='card-text'>How to Avail Solar Subsidy, If I’m Eligible?</h5>
									<h6 class='card-text'>To avail solar subsidy in India ...</h6>
									<div class='my-2'>
										<div class='btn-group'>
											<button
												type='button'
												style={{
													background: '#003056',
													color: 'white',
													textDecoration: 'none',
												}}
												class='btn btn-sm btn-outline-dark'
											>
												<Link
													to='/knowledge'
													element={<KnowledgePage />}
													onClick={() => {
														goToTopBtn();
														setKnowledgeID(4);
													}}
													style={{ textDecoration: 'none' }}
												>
													Read More
												</Link>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KnowledgeCenter;
