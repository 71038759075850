import React from 'react';

const HeadingComponent = ({ headerName, subHeading }) => {
	return (
		<div>
			<h3
				style={{
					color: '#003056',
					fontWeight: 'bold',
					textAlign: 'center',
					margin: '40px 0px',
					background: '#fff',
					padding: '25px',
				}}
			>
				{headerName}
			</h3>
		</div>
	);
};

export default HeadingComponent;
