import React from 'react';
import styled from '@emotion/styled';
import CalCulatorImage from '../../assets/searchbymenu/26.png';
import { Link } from 'react-router-dom';

const WhatsAppChat = () => {
	return (
		<Wrapper>
			<div className='top-btn'>
				<Link to='/survey'>
					<img src={CalCulatorImage} alt='' className='top-btn--icon' />
				</Link>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;

	.top-btn {
		font-size: 3rem;
		width: 3rem;
		height: 3rem;

		box-shadow: #00182b;
		border-radius: 50%;
		position: fixed;
		bottom: 1.5rem;
		left: 2rem;

		z-index: 999;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.top-btn img {
		height: 64px;
		border-radius: 30%;
	}
`;

export default WhatsAppChat;
