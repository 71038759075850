import React from 'react';
import './Blog.css';

import b1 from '../../assets/blogImages/1.jpg';
import b2 from '../../assets/blogImages/2.jpg';
import b3 from '../../assets/blogImages/3.jpg';
import b4 from '../../assets/blogImages/4.jpg';
import b5 from '../../assets/blogImages/5.jpg';

const MainBlog = ({ selectedCard }) => {
	return (
		<>
			<div className='main_blog_container'>
				<h2
					style={{
						color: '#003056',
						textAlign: '',
						margin: '2rem 5rem',
						fontWeight: '600',
					}}
				>
					BLOGS & INSIGHTS
				</h2>
				{selectedCard ? (
					<div className='card p-5 border-0' style={{ padding: '40px' }}>
						<h3 className='mx-4'>{selectedCard.title}</h3>
						<h6 className='ms-4'>{selectedCard.subtitle}</h6>
						<img
							src={selectedCard.image}
							alt='Blog_Page_Card_Image'
							className='m-4'
						/>
						<ul>
							<li className='m-sm-1 m-4 mt-4 text-justify '>
								{selectedCard.description1}
							</li>
							<li className='m-4 text-justify '>{selectedCard.description2}</li>
							<li className='m-4 text-justify '>{selectedCard.description3}</li>
							<li className='m-4 text-justify '>{selectedCard.description4}</li>
						</ul>
					</div>
				) : (
					<div
						className='default_blog'
						style={{ padding: '40px 80px', background: '#fff' }}
					>
						<h2 className='default_Blog_header'>
							Go Solar With Sunruf: India's Leading Solar Company
						</h2>
						<p className='card-text'>
							The growing demand for sustainable energy solutions has paved the way for
							innovative companies like Sunruf Solar to make their mark. Founded in
							January 2021, Sunruf Solar is redefining how people access and use solar
							power in India.
						</p>

						<p className='card-text'>
							Sunruf designs, builds, and manages end-to-end solar solutions tailored
							to meet the diverse needs of residential, commercial, and industrial
							customers across India. Whether you need solar water heaters, inverters,
							or photovoltaic panels, Sunruf has the expertise and experience to
							deliver optimized solar systems and long-term value.
						</p>

						<p className='card-text'>
							With a vision to make solar energy affordable and accessible to all,
							Sunruf is committed to high-quality, low-cost solar products and
							services. Sunruf aims to empower people and communities through
							innovative solar solutions and usher in a new era of clean, sustainable
							energy for India.
						</p>

						<div className='container-fluid p-0'>
							<div className='row w-100 mx-auto p-sm-0'>
								<div className='col-md-6 w-50 left-image-div'>
									<img src={b1} alt='' style={{ width: '100%', objectFit: 'contain' }} />
								</div>
								<div className='col-md-6 d-flex flex-column justify-content-between right-image-div'>
									<div className='w-100'>
										<img src={b2} alt='' style={{ objectFit: 'cover', width: '100%' }} />
									</div>
									<div style={{ height: '2vh' }}></div>
									<div className='w-100'>
										<img src={b3} alt='' style={{ objectFit: 'cover', width: '100%' }} />
									</div>
								</div>
							</div>
						</div>

						<hr />
						<h2 className='default_Blog_header'>
							The Sunruf Solar Story: Providing Green Energy Solutions Since 2021
						</h2>
						<p className='mb-3'>
							Sunruf makes personal solar energy consumption easier, cleaner, smarter,
							and more affordable.
						</p>

						<h4>Product and Services</h4>

						<p className='card-text'>
							Sunruf's expertise includes solar water heaters, photovoltaic panels,
							inverters, pumps, streetlights, and other engineering, procurement, and
							construction services.
						</p>

						<p className='card-text'>
							Sunruf designs, builds, and manages solar infrastructure for residential,
							commercial, and industrial use. Sunruf only recommends products
							manufactured in India to support the domestic economy.
						</p>

						<h4>The Sunruf Vision</h4>

						<p className='card-text'>
							Sunruf's founders aim to facilitate the transition to solar power and
							make it simple for people to reduce their carbon footprint. Their mission
							is to offer affordable green energy solutions for a sustainable future.
						</p>
						<div className='container-fluid p-0  right-image-div'>
							<img src={b4} alt='' style={{ width: '100%' }} />
						</div>
						<hr />
						<h2 className='default_Blog_header'>Why Choose Sunruf?</h2>

						<p className='card-text'>
							With its focus on high-quality, eco-friendly products and commitment to
							customer service, Sunruf is poised to become a leader in India's solar
							industry. Homeowners, businesses, and communities that want to go solar
							can rely on Sunruf's expertise to find the right system for their needs
							and budget.
						</p>

						<p className='card-text'>
							Sunruf is dedicated to providing exceptional customer service and
							high-quality solar products. From the initial consultation through
							installation and beyond, Sunruf specialists are available to answer any
							questions about transitioning to solar power.
						</p>

						<p className='card-text'>
							By choosing Sunruf for your solar needs, you support an Indian company
							working to make solar energy more accessible. Sunruf's high-quality,
							locally-made products and expertise in the field of solar energy allow
							them to provide the best value for your investment in harvesting power
							from the sun. Together, we can all do our part to build a sustainable
							future.
						</p>

						<div className='container-fluid p-0 left-image-div'>
							<img src={b5} alt='' style={{ width: '100%', height: '75vh' }} />
						</div>
						<hr />
						<h4>
							<b>Conclusion</b>
						</h4>

						<p className='card-text'>
							Sunruf Solar is an innovative company poised to lead India's solar energy
							revolution. With its customer-centric approach, high-quality products,
							and expert installation and maintenance services, Sunruf makes going
							solar an easy and affordable choice for homes and businesses across
							India. By utilizing the power of the sun, Sunruf's customers can reduce
							their energy costs, lower their environmental footprint, and gain energy
							independence. Under the visionary leadership of its founders, Sunruf
							Solar is bringing sustainable solar solutions to communities throughout
							India and empowering people to choose clean, renewable energy. The future
							is bright at Sunruf.
						</p>
					</div>
				)}
			</div>
		</>
	);
};

export default MainBlog;
