import React from 'react';
import img1 from '../../assets/team/4.jpg';
import img2 from '../../assets/team/p2.avif'
import img3 from '../../assets/team/p3.avif'

import './Team.css';

const Team = () => {
	return (
		<section>
			<div class='container-fluid my-4 px-3'>
				<div class='row justify-content-center'>
					<div class='col-sm-6 col-lg-3 item features-image mb-3'>
						<div class='card-wrap item-wrapper'>
							<div class='image-wrap'>
								<img src={img1} alt='Mobirise Website Builder' />
							</div>
							<div class='content-wrap'>
								<div class='text'>
									<h2 class='mbr-section-title align-left m-0'>
										<strong style={{ color: '#003056' }}>Vivek saurav</strong>
									</h2>
									<h6 class='mbr-role align-left mb-3'>Founder & CEO</h6>
									<p class='card-text align-left'>
										<i className='bi bi-envelope'> </i> viveksaurav@sunruf.com
									</p>
								</div>
								<div class='links'>
									<div class='icons-menu'>
										<a class='iconfont-wrapper' href='#!' target='_blank'>
											<span class='p-1 iconfont'>
												<i class='bi bi-linkedin'></i>
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class='col-sm-6 item features-image  col-lg-3 mb-3'>
						<div class='card-wrap item-wrapper'>
							<div class='image-wrap'>
								<img src={img2} alt='Mobirise Website Builder' />
							</div>
							<div class='content-wrap'>
								<div class='text'>
									<h3 class='mbr-section-title align-left m-0'>
										<strong style={{ color: '#003056' }}>Abhishek Singh</strong>
									</h3>
									<h6 class='mbr-role align-left mb-3 '>Founder & CTO</h6>
									<p class='card-text align-left '>
										<i className='bi bi-envelope'> </i> abhisheksingh@sunruf.com
									</p>
								</div>
								<div class='links'>
									<div class='icons-menu'>
										<a class='iconfont-wrapper' href='#!' target='_blank'>
											<span class='p-1 iconfont'>
												<i class='bi bi-linkedin'></i>
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class='col-sm-6 item features-image  col-lg-3 mb-3'>
						<div class='card-wrap item-wrapper'>
							<div class='image-wrap'>
								<img src={img3} alt='Mobirise Website Builder' />
							</div>
							<div class='content-wrap'>
								<div class='text'>
									<h2 class='mbr-section-title align-left m-0'>
										<strong style={{ color: '#003056' }}>Dixit Kumar</strong>
									</h2>
									<h6 class='mbr-role align-left mb-3 '>Founder & COO</h6>
									<p class='card-text align-left '>
										<i className='bi bi-envelope'> </i> dixitkumar@sunruf.com
									</p>
								</div>
								<div class='links'>
									<div class='icons-menu'>
										<a class='iconfont-wrapper' href='#!' target='_blank'>
											<span class='p-1 iconfont'>
												<i class='bi bi-linkedin'></i>
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Team;
