import React, { useState } from 'react';
import './KnowledgePage.css';

import LeftMainKnowledge from './LeftMainKnowledge';
import RightSubKnowledge from './RightSubKnowledge';

import img from '../../assets/knowledgeCenter/1.jpg';
import img1 from '../../assets/knowledgeCenter/2.jpg';
import img2 from '../../assets/knowledgeCenter/3.jpg';
import img3 from '../../assets/knowledgeCenter/4.jpg';
import img4 from '../../assets/knowledgeCenter/5.jpg';
import img5 from '../../assets/knowledgeCenter/6.jpg';
import img6 from '../../assets/knowledgeCenter/7.jpg';
import img7 from '../../assets/knowledgeCenter/8.jpeg';

const KnowledgeData = [
	{
		id: 1,
		image: img,
		title: 'What are the Types of Solar Power Systems?',
		subtitle: 'Solar Panel',
		description:
			'If you’re hunting for a great way to reduce your reliance on fossil fuels and save money on your energy bills then Solar power systems are the answer. But there are many different types of solar power systems available, so its important to choose the right one for your needs.',
		subDescTitle: 'Grid-Tied or On-Grid :',
		subDesc:
			'This is the most common type of solar power system. These systems are connected to the local electricity grid, providing us the opportunity to use the electricity our panels generate to power our home and sell any excess electricity back to the grid.',
		subDescTitle2: 'Off-grid :',
		subDesc2:
			' These independent setups are not connected to the electricity grid. It is typically used in remote areas where there is no grid access. Off-grid systems require a battery bank to store the electricity your panels generate.',
		subDescTitle3: 'Hybrid :',
		subDesc3:
			'This type of solar power system is a combination of a grid-tied system and an off-grid system. It is connected to the electricity grid and has a battery bank to store electricity. Hybrid systems are a good option for people who want to have backup power in case of a grid outage.',
	},

	{
		id: 5,
		image: img1,
		title: 'What are the Types of Solar Panels?',
		subtitle: 'Solar Panel',
		description:
			'The main types of solar panels are monocrystalline, polycrystalline, and thin-film.',
		subDescTitle: 'Monocrystalline :',
		subDesc:
			' Solar Panels are the most efficient type of solar panel, with up to 22% efficiency ratings. They are made from a single crystal of silicon, which allows them a uniform black appearance. Monocrystalline panels are space-efficient and tend to perform well in low-light conditions. ',
		subDescTitle2: 'Polycrystalline :',
		subDesc2:
			' Solar Panels are less efficient than monocrystalline solar panels, with up to 19% efficiency ratings. Polycrystalline panels are also made from silicon, but they use multiple crystals. These panels are more affordable than monocrystalline ones and are a good choice for those on a budget. While they may be slightly less efficient, they still offer reliable performance.',
		subDescTitle3: 'Thin-film :',
		subDesc3:
			' Solar panels are the most flexible and lightweight among the three types. They use various materials like amorphous silicon, cadmium telluride, or copper indium gallium selenide. These panels are less efficient than crystalline ones but are versatile and can be used in various applications, including on curved surfaces.',
	},

	{
		id: 2,
		image: img2,
		title: ' How Solar Can Save My Monthly Electricity Bills?',
		subtitle: 'Solar Panel',
		description:
			'Switching to solar energy can significantly reduce our monthly electricity bills in several ways.',
		subDescTitle: '1 :',
		subDesc:
			" Solar panels generate electricity from the sun's abundant and renewable energy source, which means you'll be generating your power and relying less on the grid. Solar panels can save you hundreds or even thousands of dollars on your electricity bills.",
		subDescTitle2: '2 :',
		subDesc2:
			" Here's how it works: when your solar panels produce more electricity than your home consumes during the day, the excess power is often fed back into the grid, and you receive ",
		subDescTitle3: '3 :',
		subDesc3:
			" credits for it, depending on your location and utility company. These credits can offset your electricity costs during the evening or on cloudy days when your panels aren't producing as much.",
		subDescTitle4: 'ConClusion :',
		subDesc4:
			" By reducing the reliance on traditional grid power, you'll also be less vulnerable to rising electricity prices. Over time, this can result in substantial long-term savings on your monthly bills. So, not only is solar environmentally friendly, but it's also a smart financial choice that can benefit both your wallet and the planet.",
	},

	{
		id: 4,
		image: img3,
		title: 'How to Avail Solar Subsidy, If I’m Eligible?',

		subtitle:
			'To avail solar subsidy in India, if you are eligible, follow these steps',
		description:
			'Switching to solar energy can significantly reduce our monthly electricity bills in several ways.',

		subDescTitle: '1 :',
		subDesc:
			' Check eligibility: Eligibility typically depends on factors such as your location, the type of solar system you plan to install, and your income level.',

		subDescTitle2: '2 :',
		subDesc2:
			' Research programs: Look for government-sponsored solar subsidy programs in your area. These programs often have specific requirements and application deadlines, so be sure to gather all necessary information.',

		subDescTitle3: '3 :',
		subDesc3:
			' Register with the National Portal for Rooftop Solar: Visit the National Portal for Rooftop Solar website and register yourself. You need to provide your details, including your name, contact information, and address.',

		subDescTitle4: '4 :',
		subDesc4:
			' Apply for the solar subsidy: Once you have registered on the portal, you will need to provide the following information:',
		subDescPoint1: '1. Your rooftop space details',
		subDescPoint2: '2. The size of the solar panel system you want to install',
		subDescPoint3: '3. The name of the solar installer you have chosen',

		subDescTitle5: '5 :',
		subDesc5:
			' Get your application approved: The portal will review your application and approve it if you meet all the eligibility criteria',

		subDescTitle6: '6 :',
		subDesc6:
			' Install your solar panel system: Once your application is approved, you can install your solar panel system through the solar installer you have chosen.',

		subDescTitle7: '7 :',
		subDesc7:
			' Claim your subsidy: After installing the solar panel system, you can claim your subsidy by submitting the following documents to the portal:',
		subDescPoint4: '1.  A copy of your solar panel installation certificate',
		subDescPoint5:
			'2. A copy of your bank account details The portal will then transfer the subsidy amount directly to your bank account.',
	},

	{
		id: 3,
		image: img4,
		title: 'How to Select a Solar Installation Company?',
		subtitle: 'Solar Panel',
		description:
			'Choosing the right solar installation company is crucial to ensure that you get a high-quality system that is installed properly. Follow these steps to make an informed decision: ',
		subDescTitle: '1. Research and recommendations:',
		subDesc:
			' Ask your friends, family, or neighbors who have gone solar for recommendations. You can also research local solar companies online.',
		subDescTitle2: '2. Credentials and licensing:',
		subDesc2:
			' Make sure the company is properly licensed and accredited by relevant authorities.',
		subDescTitle3: '3. Experience and track record:',
		subDesc3:
			" Consider the company's experience in the solar industry and read customer reviews to learn about their satisfaction.",
		subDescTitle4: '4. Quality of products:',
		subDesc4:
			'  Assess the quality of solar panels and equipment the company uses. High-quality components can significantly impact the performance and longevity of your solar system.',
		subDescTitle5: '5. Warranty and support',
		subDesc5:
			'Inquire about warranties and after-sales support. A reputable company should offer warranties on both equipment and workmanship to protect your investment.',
		subDescTitle6: '6. Price and financing : ',
		subDesc6:
			'Get multiple quotes to compare prices and financing options. Be cautious of deals that seem too good to be true, as quality should not be compromised for a lower price.',
		subDescTitle7: '7. Interview the company representatives : ',
		subDesc7:
			'Ask the company representatives about their products, services, and installation process.',
	},

	{
		id: 6,
		image: img5,
		title: 'How to Choose a Compatible Solar Power System for Your Home?',
		subtitle: 'Solar Panel',
		description:
			'Choosing a compatible solar power system for your home is important to ensure that you get the most out of your investment. Follow these tips:',
		subDescTitle: '1. Assess your energy needs:',
		subDesc:
			' Review your past electricity bills to understand your energy usage patterns. This will help you determine the size of the solar power system you need.',
		subDescTitle2: "2. Evaluate your roof's suitability:",
		subDesc2:
			" Examine your roof's condition and orientation to ensure that it can support the weight of solar panels and receives enough sunlight.",

		subDescTitle3: '3. Consider your budget:',
		subDesc3:
			' Solar panel costs vary based on the system size and quality. Factor in financial incentives, tax credits, and financing options available in your area.',
		subDescTitle4: '4. Define your goals:',
		subDesc4:
			' Do you want to offset your entire electricity bill or just a portion of it? Do you want to have backup power in case of a grid outage? Once you know what you want, you can start shopping for a solar power system.',
		subDescTitle5: '5. Choose the right type of solar panels',
		subDesc5:
			' There are three main types of solar panels: monocrystalline, polycrystalline, and thin-film. Each type has its own pros and cons in terms of efficiency, cost, and appearance.',
		subDescTitle6: '6. Research the warranty and manufacturer reputation : ',
		subDesc6:
			'Make sure to choose a solar panel manufacturer and installer with a good reputation and a strong warranty.',
		subDesc7:
			'By following these tips, you can choose a compatible solar power system that meets your needs and budget.',
	},

	{
		id: 7,
		image: img6,
		title: 'Best Solar Panel to Install?',
		subtitle: 'Solar Panel',
		description:
			'To choose the best solar panel, consider the following factors:',
		subDescTitle: '1. Efficiency:',
		subDesc:
			' High-efficiency panels can generate more electricity from the same amount of sunlight. Monocrystalline panels are the most efficient, but they are also the most expensive.',
		subDescTitle2: '2. Cost:',
		subDesc2:
			" Solar panels can be a significant investment, so it's important to consider your budget. Compare prices from different manufacturers and installers to get the best deal.",
		subDescTitle3: '3. Durability : ',
		subDesc3:
			"Solar panels are exposed to the elements, so it's important to choose panels that are built to last. Look for panels with a strong frame and a good warranty.",
		subDescTitle4: '4. Space : ',
		subDesc4:
			'The amount of available space on your roof will determine the size and number of panels you can install. Some panels are more compact and efficient, making them suitable for smaller rooftops.',
		subDescTitle5: '5. Incentives and rebates :',
		subDesc5:
			'Check for any government incentives or rebates that can offset the initial cost of your solar panel installation.',

		subDesc6:
			'Consulting with a solar professional can help you make an informed decision tailored to your specific situation.',
	},

	{
		id: 8,
		image: img7,
		title: 'How to Calculate Solar Power System Capacity?',
		subtitle: 'Solar Panel',
		description:
			'To calculate the capacity of a solar power system required to meet a given peak power consumption, follow this formula:',
		subDesc:
			' Solar power system capacity = Peak power consumption / Average daily sunlight hours / Solar panel efficiency',
		subDescTitle2: '1. Peak power consumption: ',
		subDesc2:
			'This is the maximum amount of power that your home or business consumes at any given time. Look at your past utility bills to find out how many kilowatt-hours (kWh) of electricity you use on average each month. This will give you an idea of the amount of energy you need to generate.',
		subDescTitle3: '2. Average daily sunlight hours : ',
		subDesc3:
			'This is the average number of hours of sunlight that your location receives each day. This information is usually available online or from your local weather service. Solar panels generate electricity based on the amount of sunlight they receive, so this factor is critical.',
		subDescTitle4: '3. Solar panel efficiency : ',
		subDesc4:
			'The amount of available space on your roof will determine the size and number of panels you can install. Some panels are more compact and efficient, making them suitable for smaller rooftops.',
		subDescTitle5: '4. Incentives and rebates :',
		subDesc5:
			'This is the percentage of sunlight that a solar panel can convert into electricity. The efficiency of solar panels varies depending on the type of solar panel.',
		subDescTitle6: '5. Battery storage (optional) : ',
		subDesc6:
			" If you want to store excess energy for use during the night or on cloudy days, you'll need to factor in the capacity of a battery storage system.",
		subDescTitle7: '6. System losses : ',
		subDesc7:
			"Solar power systems experience some losses due to factors such as wiring, dust, and temperature. It's important to account for these losses when calculating the size of your system.",
		subDesc8:
			'It is always best to consult with a qualified solar installer to get an accurate estimate of the size of the solar power system you need.',
	},
];

const KnowledgePage = ({ knowledgeID }) => {
	const [selectedKnowledgeCard, setSelectedKnowledgeCard] = useState(
		KnowledgeData.find((data) => data.id === knowledgeID)
	);

	const handleCardClick = (card) => {
		setSelectedKnowledgeCard(card);
	};

	return (
		<div className='container-fluid p-0'>
			<div className='row m-0 justify-content-center'>
				<div className='col-lg-8'>
					<div className='row'>
						<LeftMainKnowledge selectedKnowledgeCard={selectedKnowledgeCard} />
					</div>
				</div>
				<div className='col-lg-3 p-0'>
					<RightSubKnowledge cards={KnowledgeData} onCardClick={handleCardClick} />
				</div>
			</div>
		</div>
	);
};

export default KnowledgePage;
