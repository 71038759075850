import React, { useEffect, useState } from 'react';
import './Navbar.css';
import NavbarModal from './NavbarModal';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';

const Navbar = () => {
	const [scrolled, setScrolled] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const offset = window.scrollY;
			if (offset > 200) {
				setScrolled(true);
			} else if (offset > 10) {
				setOpen(false);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const goToTopBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	};

	const clickOnModalIcon = () => {
		setOpen(!open);
	};

	const navLinks = [
		{
			title: 'Home',
			link: '',
		},
		{
			title: 'Solar Solution',
			link: 'rooftop',
		},
		{
			title: 'Blogs',
			link: 'blogs',
		},
		{
			title: 'Book Site Visit',
			link: 'survey',
		},
		{
			title: 'About Us',
			link: 'about',
		},
		{
			title: 'Contact',
			link: 'contact',
		},
	];

	return (
		<nav
			className={`custom-nav-class navContainer navbar navbar-expand-lg navbar-dark p-md-2 ${
				scrolled ? 'sticky-header' : ''
			}`}
		>
			<div className='container nav-container'>
				<div className='leftMenu'>
					<Link to={'/'} className='navbar-brand'>
						<img src={logo} alt='' style={{ height: '60px' }} />
					</Link>

					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarNav'
						aria-controls='navbarNav'
						aria-expanded={open ? 'true' : 'false'}
						aria-label='Toggle navigation'
						onClick={clickOnModalIcon}
					>
						<span className='navbar-toggler-icon'></span>
					</button>
				</div>

				<div
					className={`${open ? 'show' : ''} collapse navbar-collapse center-navs`}
					id='navbarNav'
				>
					<div className='mx-auto'></div>
					<ul style={{ alignItems: 'left' }} className='navbar-nav'>
						{navLinks.map((nav, index) => (
							<li key={index} className='nav-item' onClick={() => setOpen(false)}>
								<Link
									to={`/${nav.link}`}
									className='nav-link text-white'
									onClick={goToTopBtn}
								>
									{nav.title}
								</Link>
							</li>
						))}
					</ul>
				</div>
				<div className='navbarModalButton'>
					<NavbarModal />
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
