import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import Profile from './profile/Profile';

const SignIn = () => {
	const [mobileNumber, setMobileNumber] = useState('');

	const handleMobileNumberChange = (e) => {
		const re = /^[0-9]{0,10}$/;
		if (re.test(e.target.value) || e.target.value === '') {
			setMobileNumber(e.target.value);
		}
	};

	return (
		<div
			className='container vh-100'
			style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
		>
			<main class='form-signin w-50 mx-auto'>
				<form>
					<h4 class='mb-3 fw-bolder text-center' style={{ color: '#003056' }}>
						Welcome to SunRuf - Switch To Solar
					</h4>
					<h6 class='h6 mb-3 fw-normal text-center'>Login to access your account</h6>

					<div class='form-floating mb-3'>
						<input
							type='email'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={mobileNumber}
							onChange={handleMobileNumberChange}
						/>
						<label for='floatingInput'>
							Mobile Number<b style={{ color: 'red' }}>*</b>
						</label>
					</div>
					<div class='form-floating mb-3'>
						<input
							type='password'
							class='form-control'
							id='floatingPassword'
							placeholder='Password'
						/>
						<label for='floatingPassword'>
							Password<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<Link to='/forgotPassword' element={<ForgotPassword />}>
						<div class='checkbox my-3'>
							<p style={{ color: '#003056' }}>Forgot Password?</p>
						</div>
					</Link>
					<Link to='/profile' element={<Profile />}>
						<button class='w-50 btn btn-lg btn-primary mx-auto' type='submit'>
							Sign in
						</button>
					</Link>

					<p class='mt-3 mb-2 text-muted'>
						Don't have an account?{' '}
						<Link to='/signup' element={<SignUp />} style={{ color: '#003056' }}>
							Register Now
						</Link>
					</p>
				</form>
			</main>
		</div>
	);
};

export default SignIn;
