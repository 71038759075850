import React from 'react';
import Slider from 'react-slick';
// import { Link } from 'react-router-dom';

import img1 from '../assets/approved/1.png';
import img2 from '../assets/approved/7.png';
import img3 from '../assets/approved/3.jpg';
import img4 from '../assets/approved/4.png';
import img5 from '../assets/approved/5.gif';
import img6 from '../assets/approved/6.png';
import HeadingComponent from './headingComponent/HeadingComponent';

import { LazyLoadImage } from 'react-lazy-load-image-component';

function SampleNextArrow(props) {
	const { abNext, style, onClick } = props;
	return (
		<div
			className={abNext}
			style={{
				...style,
				display: 'block',
				background: '#003056',
				borderRadius: '50%',
			}}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { abBefore, style, onClick } = props;
	return (
		<div
			className={abBefore}
			style={{
				...style,
				display: 'block',
				background: '#003056',
				borderRadius: '50%',
			}}
			onClick={onClick}
		/>
	);
}

var settings = {
	infinite: true,
	margin: 10,
	speed: 1000,
	autoplay: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	initialSlide: 0,
	nextArrow: <SampleNextArrow />,
	prevArrow: <SamplePrevArrow />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

const AprrovedBy = () => {
	return (
		<div className='container-fluid'>
			<HeadingComponent
				headerName={'Bihar’s Leading Rooftop Solar Startup Approved by'}
			/>
			<div className='container'>
				<Slider {...settings}>
					<div className='slideWrapper__approvedBy'>
						<LazyLoadImage src={img1} alt='' style={{ margin: '0px auto' }} />
					</div>
					<div className='slideWrapper__approvedBy'>
						<LazyLoadImage src={img2} alt='' />
					</div>
					<div className='slideWrapper__approvedBy'>
						<LazyLoadImage src={img3} alt='' />
					</div>
					<div className='slideWrapper__approvedBy'>
						<LazyLoadImage src={img4} alt='' style={{ padding: '1rem' }} />
					</div>
					<div className='slideWrapper__approvedBy'>
						<LazyLoadImage src={img5} alt='' />
					</div>
					<div className='slideWrapper__approvedBy'>
						<LazyLoadImage src={img6} alt='' />
					</div>
				</Slider>
			</div>
		</div>
	);
};

export default AprrovedBy;
