import React, { useState } from 'react';
import './Blog.css';
import MainBlog from './MainBlog';
import SubBlogs from './SubBlogs';

import img1 from '../../assets/solarSolutionsGrid/4.jpeg';
import img2 from '../../assets/solarSolutionsGrid/3.avif';
import img3 from '../../assets/solarSolutionsGrid/5.jpg';
import img4 from '../../assets/solarSolutionsGrid/6.jpeg';
import img5 from '../../assets/solarSolutionsGrid/SOLAR MILL.png';
import img6 from '../../assets/solarSolutionsGrid/SOLAR WATER PUMP.jpg';
import img7 from '../../assets/solarSolutionsGrid/1.jpeg';
import img8 from '../../assets/solarSolutionsGrid/2.webp';
import img9 from '../../assets/solarSolutionsGrid/OFF GRID SOLAR.jpg';
import img10 from '../../assets/solarSolutionsGrid/inverter.jpg';
import img11 from '../../assets/solarSolutionsGrid/net.jpg';
import img12 from '../../assets/solarSolutionsGrid/battery.webp';
import img13 from '../../assets/solarSolutionsGrid/financing.jpg';

const dummyData = [
	{
		id: 1,
		image: img1,
		title: 'On Grid Solar',
		subtitle: 'Solar Panel',
		description1:
			'Grid solar systems represent a dynamic and transformative approach to harnessing the abundant power of solar energy. These systems seamlessly integrate solar panels into the existing electrical grid, making solar energy accessible and efficient for both residential and commercial applications',
		description2:
			'Grid solar systems offer numerous benefits. They reduce electricity bills by generating clean energy from the sun, while excess power can be fed back into the grid, providing savings and potential income. Moreover, they reduce the carbon footprint, combat climate change, and enhance energy security by decentralizing energy production. Grid solar systems are scalable, making them suitable for various needs, from individual households to large industrial facilities.',
		description3:
			'Grid solar systems offer numerous advantages. First and foremost, they reduce the reliance on fossil fuels, mitigating greenhouse gas emissions and combatting climate change. Moreover, they help lower energy bills for users, offering a reliable source of clean energy. Surplus energy can even be sold back to the grid, providing an extra income stream. These systems also reduce strain on the grid by distributing power generation, enhancing grid resilience.',
		description4:
			"In a world increasingly focused on sustainable living and combating environmental challenges, grid solar systems play a pivotal role in transitioning to cleaner and more efficient energy sources. They offer an accessible and environmentally friendly solution, empowering us to harness the sun's power for a brighter and more sustainable future.",
	},
	{
		id: 2,
		image: img2,
		title: 'OFF Grid Solar',
		subtitle: 'Solar energy is a sustainable and renewable',
		description1:
			'Off-grid solar systems represent a remarkable leap in energy independence and sustainability. Unlike grid-tied systems, they function autonomously, providing power in remote areas or for those looking to disconnect from traditional utility grids',
		description2:
			'Off-grid solar systems rely on solar panels, batteries, and inverters to capture and store energy from the sun. They are particularly valuable in remote or off-grid locations where accessing a traditional power grid is challenging or cost-prohibitive. These systems provide reliable electricity for homes, cabins, and businesses, reducing dependence on fossil fuels and mitigating environmental impact.',
		description3:
			"Moreover, off-grid solar systems contribute to energy resilience, offering a backup power source during grid outages or emergencies. Their eco-friendly nature reduces greenhouse gas emissions, benefiting both the environment and the users' long-term financial savings.",
		description4:
			'As the world shifts towards cleaner and sustainable energy solutions, off-grid solar systems prove to be a practical and environmentally responsible choice, enabling self-sufficiency and reducing the ecological footprint in a rapidly changing energy landscape.',
	},
	{
		id: 3,
		image: img3,
		title: 'Hybrid Solar',
		subtitle: 'Harnessing the Power of Solar Energy with Solar Street Light.',
		description1:
			'Hybrid solar systems are a dynamic and innovative approach to solar energy utilization. They seamlessly integrate both solar panels and energy storage solutions, such as batteries, with the electrical grid. These systems offer a flexible and efficient way to capture, store, and utilize solar energy, even when the sun is not shining.',
		description2:
			'One of the primary advantages of hybrid solar systems is their ability to store excess energy generated during the day, which can be used during the night or on cloudy days. This enhances energy reliability and reduces dependence on traditional grid power. Furthermore, these systems are often designed to maximize self-consumption, optimizing cost savings for users.',
		description3:
			'Hybrid solar systems promote sustainability by reducing greenhouse gas emissions and lowering electricity bills. They also offer grid support by injecting surplus power back into the grid, promoting grid stability.',
		description4:
			'In a world focused on sustainability and energy efficiency, hybrid solar systems are a forward-thinking solution that empowers individuals and businesses to harness the full potential of solar energy while ensuring a reliable and eco-friendly energy supply.',
	},

	{
		id: 4,
		image: img4,
		title: '(EV) Charging Stations',
		subtitle: 'Harnessing the Power of Solar Energy with Hybrid Solar System.',
		description1:
			'Electric Vehicle (EV) charging stations have emerged as a critical component in the transition towards a sustainable and clean transportation sector. By harnessing the power of solar energy for these charging stations, we can significantly reduce our dependence on fossil fuels and combat climate change.',
		description2:
			'Solar-powered EV charging stations utilize photovoltaic panels to capture sunlight and convert it into electricity, which is then used to charge electric vehicles. This eco-friendly approach not only reduces greenhouse gas emissions but also makes EVs an even greener choice.',
		description3:
			'Solar EV charging stations promote energy independence and decentralization by producing clean energy locally. They are ideal for urban areas, highways, and parking facilities, enhancing the accessibility and convenience of electric vehicles. Moreover, they reduce the strain on the electrical grid during peak demand periods.',
		description4:
			'As our world seeks to mitigate the environmental impact of transportation, solar-powered EV charging stations offer a sustainable solution, revolutionizing the way we power our vehicles and contributing to a cleaner and more sustainable future.',
	},

	{
		id: 5,
		image: img5,
		title: 'Oil, Flour and Masala Mills.',
		subtitle:
			'Harnessing the Power of Solar Energy with Oil, Floor and Masala Mills.',
		description1:
			'Harnessing the power of solar energy in oil, flour, and masala mills presents a transformative opportunity to reduce environmental impact and improve energy efficiency in the food processing industry. These mills are energy-intensive operations, making solar energy adoption a viable and eco-friendly choice.',
		description2:
			"Solar installations in these mills can significantly cut electricity costs and reduce the carbon footprint. Solar panels can be integrated into the mill's infrastructure, providing a sustainable source of electricity to power machines, lighting, and cooling systems. Excess solar power generated during sunny periods can be stored or even sold back to the grid, offering potential revenue streams.",
		description3:
			"Utilizing solar energy in these mills contributes to energy independence, mitigates greenhouse gas emissions, and enhances business sustainability. Additionally, it reduces the mills' vulnerability to fluctuating energy prices and grid outages.",
		description4:
			'In summary, integrating solar energy into oil, flour, and masala mills not only makes economic sense but also plays a crucial role in the global shift towards more sustainable and environmentally responsible food processing practices.',
	},

	{
		id: 6,
		image: img6,
		title: 'Solar Water Pump',
		subtitle: 'Harnessing the Power of Solar Energy with Solar Water Pump.',
		description1:
			'Solar water pumps represent a transformative innovation in agriculture and water resource management. These systems utilize solar energy to power water pumps, making them highly efficient and environmentally friendly.',
		description2:
			'Solar water pumps are particularly valuable in remote or off-grid areas where electricity supply is limited or unreliable. They provide a consistent and sustainable source of energy to irrigate crops, provide clean drinking water, and support various agricultural and community needs. By reducing the dependence on fossil fuels and grid electricity, they significantly lower operating costs, boost agricultural productivity, and reduce greenhouse gas emissions.',
		description3:
			'These systems also enhance resilience in the face of climate change, as they operate even in the absence of power grids or fuel sources. Moreover, they alleviate the burden of manual water pumping, especially for rural communities, and reduce the environmental impact of traditional diesel or electric pumps.',
		description4:
			'As we navigate the challenges of food security and water availability, solar water pumps serve as a pivotal tool in harnessing the power of the sun to address these critical global needs.',
	},

	{
		id: 7,
		image: img7,
		title: 'Solar Street Light',
		subtitle: 'Harnessing the Power of Solar Energy with Solar Street Light.',
		description1:
			"Solar street lights are a sustainable and efficient solution for urban and rural lighting needs. These systems harness the sun's energy during the day and use it to illuminate streets, pathways, and public spaces at night, offering several significant advantages.",
		description2:
			'Solar street lights reduce electricity costs for municipalities, businesses, and homeowners by operating independently of the grid. They are especially valuable in remote or off-grid areas where extending traditional electrical infrastructure is impractical. The absence of wiring also enhances safety by eliminating the risk of accidents due to exposed wires.',
		description3:
			'Environmentally, solar street lights reduce carbon emissions and reliance on fossil fuels, contributing to a cleaner planet. Their self-sustaining nature and low maintenance requirements make them a cost-effective and long-term lighting solution.',
		description4:
			'By combining renewable energy and modern technology, solar street lights brighten communities while promoting sustainability. These systems represent a bright path forward, demonstrating how we can harness solar energy to illuminate our world, reduce energy costs, and contribute to a greener and more efficient future.',
	},

	{
		id: 8,
		image: img8,
		title: 'Solar Water Heater',
		subtitle: 'Harnessing the Power of Solar Energy with Solar Water Heater.',
		description1:
			'Solar water heaters are a sustainable and cost-effective solution for meeting hot water demands in homes and businesses. These systems utilize sunlight to heat water, reducing the need for conventional water heating methods, such as electric or gas-powered systems.',
		description2:
			'Solar water heaters typically consist of solar collectors, which absorb and convert sunlight into thermal energy, and storage tanks to store the heated water. This technology helps lower energy bills, as it reduces the reliance on non-renewable energy sources. It also contributes to a reduction in greenhouse gas emissions, making it an eco-friendly choice.',
		description3:
			'Moreover, solar water heaters are versatile and adaptable to various climates, making them a viable option worldwide. They promote energy independence, resilience, and long-term cost savings. As the world increasingly emphasizes sustainability, solar water heaters stand as an effective means to harness the power of solar energy and integrate it into our daily lives, helping us move towards a cleaner and more sustainable energy future.',
	},

	{
		id: 9,
		image: img9,
		title: 'Solar Panels / (PV) Modules',
		subtitle: 'Solar Panels / Photovoltaic (PV) Modules',
		description1:
			'Solar panels, often referred to as photovoltaic (PV) modules, are groundbreaking devices designed to convert sunlight into electricity. They play a crucial role in our transition towards cleaner and more sustainable energy sources.',
		description2:
			'At the heart of a solar panel is the PV module. This module consists of multiple solar cells, typically made from crystalline silicon, although other materials like thin-film technology are also used. When sunlight strikes these solar cells, the photons in the light excite the electrons in the cells, causing them to move and create an electric current. This process is known as the photovoltaic effect, from which the term "PV" is derived.',
		description3:
			'Solar panels are usually organized into arrays, with numerous PV modules interconnected to generate a higher voltage and power output. These arrays are strategically positioned to capture the maximum amount of sunlight during the day. The direct current (DC) electricity produced by the solar panels is then sent to an inverter, which converts it into alternating current (AC), suitable for use in homes and businesses.',
		description4:
			'Net metering may allow excess electricity to be fed back into the grid, earning credit for the system owner. Solar panels offer clean, renewable energy, reduce electricity bills, and contribute to a significant reduction in greenhouse gas emissions. As we strive for a more sustainable energy future, solar panels and PV modules continue to lead the way in harnessing the power of the sun to meet our energy needs.',
	},

	{
		id: 10,
		image: img10,
		title: 'Solar Inverter',
		subtitle: 'Harnessing the Power of Solar Energy with Solar Inverter.',
		description1:
			'Solar inverters are a vital component of solar power systems, responsible for converting the direct current (DC) electricity generated by solar panels into the alternating current (AC) electricity used in our homes and businesses. This conversion process is essential because the majority of our electrical appliances and the power grid operate on AC power.',
		description2:
			'Solar inverters come in various types, with string inverters, microinverters, and power optimizers being the most common. String inverters are typically used in traditional solar systems, where multiple solar panels are connected in series to a single inverter. Microinverters, on the other hand, are attached to each solar panel, optimizing individual panel performance. Power optimizers are another type of inverter, which maximizes the power output of each panel in a string configuration.',
		description3:
			'The key function of a solar inverter is to manage the flow of electricity, ensuring that the system operates efficiently and safely. Modern solar inverters often come equipped with monitoring capabilities, allowing system owners to track energy production and system performance in real-time. This data helps diagnose issues quickly and optimize the system for maximum energy yield.',
		description4:
			'As the demand for solar energy continues to grow, solar inverters are becoming increasingly sophisticated, offering features such as grid support and backup power. They play a pivotal role in our transition towards clean, sustainable energy sources, making solar power practical and accessible for homes and businesses worldwide. Solar inverters are a critical link in harnessing the power of the sun to meet our energy needs efficiently and reliably.',
	},

	{
		id: 11,
		image: img11,
		title: 'Solar Net Metering',
		subtitle: 'Balancing Energy and Savings: The Promise of Solar Net Metering.',
		description1:
			'Solar net metering is a groundbreaking approach to harnessing the power of solar energy and promoting sustainable electricity generation. This system allows solar system owners to connect to the grid, providing several benefits that support the transition to cleaner and more efficient energy sources.',
		description2:
			'Net metering works by measuring the difference between the electricity a solar system generates and what is consumed. When solar panels produce surplus electricity, it is fed back into the grid, and the owner is credited for this excess energy. During periods of low solar production, such as at night or on cloudy days, the grid supplies power to the user, drawing upon the credits earned.',
		description3:
			'One of the most significant advantages of net metering is its economic benefit. It reduces electricity bills by offsetting the cost of power drawn from the grid with credits for surplus energy. This not only leads to substantial savings but also provides an incentive for solar adoption.',
		description4:
			'Furthermore, net metering promotes grid stability by distributing renewable energy across the grid and reducing strain during peak demand periods. It encourages cleaner energy production and the integration of more renewable resources, ultimately contributing to a reduction in greenhouse gas emissions. In summary, solar net metering is a powerful tool that empowers individuals and businesses to generate and share clean energy efficiently while enjoying financial benefits. It supports the global shift towards a more sustainable and environmentally responsible energy landscape, where solar power plays a pivotal role.',
	},

	{
		id: 12,
		image: img12,
		title: 'Solar Battery',
		subtitle:
			'Energizing Independence: Unleashing the Potential of Solar Batterie.',
		description1:
			'Solar batteries are a critical advancement in the utilization of solar energy, enhancing the efficiency and reliability of solar power systems. These batteries store excess electricity generated by solar panels during the day, making it available for use during the night or when solar generation is low, such as on cloudy days or during peak energy demand periods.',
		description2:
			'The key benefit of solar batteries is their ability to provide continuous power, reducing reliance on the grid and offering energy independence. By storing surplus solar energy, these batteries allow homeowners and businesses to maximize self-consumption, ultimately cutting electricity bills and reducing environmental impact. Additionally, solar batteries provide a reliable backup power source during grid outages or emergencies, ensuring uninterrupted energy supply.',
		description3:
			'Solar batteries also contribute to grid stability by releasing stored energy during peak demand, reducing strain on the grid and decreasing the need for additional power plants. They promote sustainability by increasing the use of renewable energy sources and reducing greenhouse gas emissions.',
		description4:
			'As the world shifts towards cleaner and more sustainable energy solutions, solar batteries play a pivotal role in enabling individuals and businesses to harness the full potential of solar energy. They empower users to optimize their energy usage, reduce costs, and contribute to a greener, more reliable energy future.',
	},

	{
		id: 13,
		image: img13,
		title: 'Solar Financing',
		subtitle:
			'Empowering a Solar-Powered Future: Navigating Solar Financing Option',
		description1:
			'Solar financing is essential for making solar energy accessible to homeowners, businesses, and communities. Several financing options are available, each with its own benefits and considerations',
		description2:
			'Cash Purchase: Buying a solar system outright offers long-term savings but requires a significant upfront investment. It allows you to own the system and take advantage of tax incentives and rebates.',
		description3:
			'Solar Loans: Loans allow you to finance the purchase of a solar system, spreading the cost over time while immediately reducing energy bills and potentially qualifying for incentives.',
		description4:
			'Power Purchase Agreements (PPAs): With a PPA, a third party installs and maintains the system, and you purchase the generated electricity at a predetermined rate, often lower than utility prices. Solar financing options vary in terms of cost, ownership, and savings potential, allowing individuals and businesses to choose the approach that aligns with their financial goals and energy needs.',
	},
];

const Blog = ({ selectedID }) => {
	const [selectedCard, setSelectedCard] = useState(
		dummyData.find((data) => data.id === selectedID)
	);
	console.log(selectedID, 'Hello');

	const handleCardClick = (card) => {
		setSelectedCard(card);
	};

	return (
		<div className='container-fluid p-0'>
			<div className='row m-0 justify-content-center'>
				<div className='col-lg-8 p-0'>
					<div className='row m-0'>
						<MainBlog selectedCard={selectedCard} />
					</div>
				</div>
				<div className='col-lg-3 p-0'>
					<div className='row'>
						<div className='col-12 col-md-4 p-0 col-lg-12'>
							<SubBlogs cards={dummyData} onCardClick={handleCardClick} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Blog;
