import React from 'react';
import './KnowledgePage.css';

import img8 from '../../assets/knowledgeCenter/9.png';

const LeftMainKnowledge = ({ selectedKnowledgeCard }) => {
	return (
		<>
			<div className='main_knowledge_container'>
				<h1
					style={{
						color: '#003056',
						textAlign: '',
						margin: '2rem 5rem',
						fontWeight: 'bold',
					}}
				>
					Knowledge Center
				</h1>
				{selectedKnowledgeCard ? (
					<div className='card p-5 border-0'>
						<h2 className='mx-4 my-3'>{selectedKnowledgeCard.title}</h2>
						<h6 className='mx-4 mx-md-4'>{selectedKnowledgeCard.subtitle}</h6>
						<img
							className='m-4'
							src={selectedKnowledgeCard.image}
							alt='Blog_Page_Card_Image'
						/>
						<p className='mx-4 text-justify'>{selectedKnowledgeCard.description}</p>
						<p className='mx-4 my-2'>
							<b>{selectedKnowledgeCard.subDescTitle}</b>
							{selectedKnowledgeCard.subDesc}
						</p>
						<br />
						<p className='mx-4 my-2'>
							<b>{selectedKnowledgeCard.subDescTitle2}</b>
							{selectedKnowledgeCard.subDesc2}
						</p>
						<br />
						<p className='mx-4 my-2'>
							<b>{selectedKnowledgeCard.subDescTitle3}</b>
							{selectedKnowledgeCard.subDesc3}
						</p>
						<br />
						<p className='mx-4 my-2'>
							<b>{selectedKnowledgeCard.subDescTitle4}</b>
							{selectedKnowledgeCard.subDesc4}
							<ul style={{ listStyle: 'none', marginLeft: '30px' }}>
								<li>{selectedKnowledgeCard.subDescPoint1}</li>
								<li>{selectedKnowledgeCard.subDescPoint2}</li>
								<li>{selectedKnowledgeCard.subDescPoint3}</li>
							</ul>
						</p>
						<br />
						<p className='mx-4 my-2'>
							<b>{selectedKnowledgeCard.subDescTitle5}</b>
							{selectedKnowledgeCard.subDesc5}
						</p>
						<br />
						<p className='mx-4 my-2'>
							<b>{selectedKnowledgeCard.subDescTitle6}</b>
							{selectedKnowledgeCard.subDesc6}
						</p>
						<br />
						<p className='mx-4 my-2'>
							<b>{selectedKnowledgeCard.subDescTitle7}</b>
							{selectedKnowledgeCard.subDesc7}
							<ul style={{ listStyle: 'none', marginLeft: '30px' }}>
								<li>{selectedKnowledgeCard.subDescPoint4}</li>
								<li>{selectedKnowledgeCard.subDescPoint5}</li>
							</ul>
						</p>
						<br />
						<p className='mx-4 my-2'>
							<b>{selectedKnowledgeCard.subDescTitle8}</b>
							{selectedKnowledgeCard.subDesc8}
						</p>
					</div>
				) : (
					<div className='card p-5'>
						<h2 className='mx-4 my-3'>
							What is the Cost of a 5KW & 3KW Solar Power Plant for a Home?
						</h2>
						<h6 className='mx-4'>Solar Panel</h6>
						<img className='m-4' src={img8} alt='Blog_Page_Card_Image' />

						<p className='mx-4 text-justify'>
							The cost of a 5kW or 3kW solar power plant for your home will vary
							depending on several factors, including:
						</p>

						<ul style={{ width: '80%', margin: 'auto' }}>
							<li>
								The type, efficiency, and quality of the solar panels and other
								components
							</li>
							<li>The size of your roof and the amount of shading on your roof.</li>
							<li>The cost of labor in your area</li>
						</ul>

						<p className='mx-4 text-justify mt-4'>
							In general, a 5kW solar power plant will cost between ₹3.5 lakhs and ₹6
							lakhs, while a 3kW solar power plant will cost between ₹1.5 lakhs to ₹2.5
							lakhs
						</p>

						<p className='mx-4 my-1'>
							However, it is important to note that these are just estimates. The
							actual cost of your solar power system will vary depending on the
							specific factors mentioned above.
						</p>

						<br />
						<p className='mx-4'>
							Here are some tips for saving money on your solar power system:
						</p>

						<ul style={{ width: '80%', margin: 'auto' }}>
							<li>Get quotes from multiple solar installers.</li>
							<li>Compare the prices of different solar panels and components.</li>
							<li>
								Ask about discounts for bulk purchases or for referring friends and
								family.
							</li>
							<li>
								Consider financing options. Many solar installers offer financing
								options that can make it easier to afford a solar power system.
							</li>
							<li>
								Take advantage of government subsidies. In India, there are several
								government subsidies available for solar power systems. You can find
								more information about these subsidies on the website of the Ministry of
								New and Renewable Energy.
							</li>
						</ul>

						<p className='mx-4 mt-4'>
							By following these tips, you can save money on your solar power system
							and start saving money on your electricity bills.
						</p>
					</div>
				)}
			</div>
		</>
	);
};

export default LeftMainKnowledge;
