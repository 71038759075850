import React from 'react';
import './About.css';
import img1 from '../../assets/banner/about_us_banner.jpg';
import img2 from '../../assets/banner/aboutus.jpg';
import Banner from '../../components/banner/Banner';
import Team from '../../components/team/Team';

const About = () => {
	return (
		<div className='container-fluid p-0'>
			<Banner title={'ABOUT US'} bgImg={img1} />
			<div className='container about_container'>
				<div class='card my-3 border-0'>
					<div class='row g-0'>
						<div class='col-md-6 d-flex'>
							<img src={img2} class='img-fluid rounded-start' alt='...' />
						</div>
						<div class='col-md-6'>
							<div class='card-body p-5 border-0'>
								<h5 class='card-title about_title'>About Sunruf</h5>
								<h6 className='card-text about_h6_text'>
									Sunruf Solar Panels: Your Gateway to Sustainable Energy
								</h6>
								<p class='card-text about_text'>
									As technology keeps advancing, we find exciting new ways to tap into
									the abundant energy of the sun. Think of the sun as our life-giving
									friend, generously showering us with light and warmth. At Sunruf, our
									mission is to help you make smarter choices in life by harnessing the
									power of the sun.
									<br />
									<br />
									Sunruf was born out of a desire to make personal solar energy
									consumption easier, cleaner, more intelligent, and more affordable.
									Back in January 2021, Vivek Saurav, Abhishek Singh, and Dixit Kumar had
									a vision, and today, Sunruf Solar stands as one of India's leading
									solar companies
									<br />
									<br />
									Our expertise spans a wide range of solar solutions, including solar
									water heaters, solar inverters, solar pumps, solar street lights, and
									various EPC (Engineering, Procurement, and Construction) services.
									We're proud to say that when it comes to solar power systems, we
									recommend using Made in India products to boost our nation's economy.
									So, “aapka paisa aapke desh mein”.
									<br />
									<br />
									Sunruf is a company that still loves to learn & grow in all manners.
									Our motto is simple: Go Green and embrace natural energy sources
									whenever possible. We believe that by encouraging people to make the
									switch, we can all contribute to a greener, more sustainable future.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Team />
			</div>
		</div>
	);
};

export default About;
