import React from 'react';
import img1 from '../assets/solarrooftopsolutions/1.png';
import img2 from '../assets/solarrooftopsolutions/2.png';
import img3 from '../assets/solarrooftopsolutions/3.png';
import img4 from '../assets/solarrooftopsolutions/4.png';
import img5 from '../assets/solarrooftopsolutions/5.png';
import img6 from '../assets/solarrooftopsolutions/6.png';
import img7 from '../assets/solarrooftopsolutions/7.png';
import img8 from '../assets/solarrooftopsolutions/8.png';
import HeadingComponent from './headingComponent/HeadingComponent';

import { Link } from 'react-router-dom';

const RoofTopSolorSolutions = () => {
	const goToTopBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	};

	return (
		<div className='container-fluid mb-4'>
			<HeadingComponent headerName={'Rooftop Solar Solution'} />
			<div className='searchByMenu'>
				<div className='RWrapper'>
					<Link to='/solarExpert' onClick={goToTopBtn}>
						<img src={img1} alt='1' height='100px' style={{ margin: '0px auto' }} />
						<h6 className='text-center my-2 roof-top-title'>SOLAR CONSULTANCY</h6>
					</Link>
				</div>
				<div className='RWrapper'>
					<Link to='/survey' onClick={goToTopBtn}>
						<img src={img2} alt='' height='100px' style={{ margin: '0 auto' }} />
						<h6 className='text-center my-2 roof-top-title'>
							Book Free Solar Site Survey
						</h6>
					</Link>
				</div>
				<div className='RWrapper'>
					<Link to='/solarExpert' onClick={goToTopBtn}>
						<img src={img3} alt='' height='100px' style={{ margin: '0 auto' }} />
						<h6 className='text-center my-2 roof-top-title'>SOLAR INSURANCE</h6>
					</Link>
				</div>
				<div className='RWrapper'>
					<Link to='/solarExpert' onClick={goToTopBtn}>
						<img src={img4} alt='' height='100px' style={{ margin: '0 auto' }} />
						<h6 className='text-center my-2 roof-top-title'>SOLAR FINANCING</h6>
					</Link>
				</div>
				<div className='RWrapper'>
					<Link to='/solarExpert' onClick={goToTopBtn}>
						<img src={img5} alt='' height='100px' style={{ margin: '0 auto' }} />{' '}
						<h6 className='text-center my-2 roof-top-title'>
							SOLAR INSTALLATION & COMMISSIONING
						</h6>
					</Link>
				</div>
				<div className='RWrapper'>
					<Link to='/solarExpert' onClick={goToTopBtn}>
						<img src={img6} alt='' height='100px' style={{ margin: '0 auto' }} />{' '}
						<h6 className='text-center my-2 roof-top-title'>
							SOLAR ENGINEERING & DESIGNING
						</h6>
					</Link>
				</div>
				<div className='RWrapper'>
					<Link to='/solarExpert' onClick={goToTopBtn}>
						<img src={img7} alt='' height='100px' style={{ margin: '0 auto' }} />{' '}
						<h6 className='text-center my-2 roof-top-title'>
							SOLAR PANEL CLENAING & SERVICE
						</h6>
					</Link>
				</div>
				<div className='RWrapper'>
					<Link to='/solarExpert' onClick={goToTopBtn}>
						<img src={img8} alt='' height='100px' style={{ margin: '0 auto' }} />{' '}
						<h6 className='text-center my-2 roof-top-title'>SOLAR AMC</h6>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default RoofTopSolorSolutions;
