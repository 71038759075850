import React from 'react';
import './Footer.css';
import logo from '../../assets/footerLogo.png';
import Extra from '../Extra';
import { Link } from 'react-router-dom';

import About from '../../pages/about/About';
import Contact from '../../pages/contact/Contact';
import ReferEarn from '../../pages/profile/ReferEarn';
import Blog from '../../pages/blog/Blog';

const Footer = () => {
	const goToTopBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	};

	return (
		<footer
			class='pt-3'
			id='Contact'
			style={{ background: '#003056', color: '#fff' }}
		>
			<div class='container px-4'>
				<div class='row'>
					<div class='col-md-3 col-sm-6 container my-4'>
						<h5>Quick Links</h5>
						<ul className='useful_links list-unstyled'>
							<li>
								<Link
									to='/'
									onClick={goToTopBtn}
									class='text-decoration-none text-white'
								>
									Home
								</Link>
							</li>
							<li>
								<Link
									to='/about'
									element={<About />}
									onClick={goToTopBtn}
									class='text-decoration-none text-white'
								>
									About Us
								</Link>
							</li>
							<li>
								<Link
									to='/contact'
									element={<Contact />}
									onClick={goToTopBtn}
									class='text-decoration-none text-white'
								>
									Contact Us
								</Link>
							</li>
							<li>
								<Link
									to='/blogs'
									element={<Blog />}
									onClick={goToTopBtn}
									class='text-decoration-none text-white'
								>
									Blogs
								</Link>
							</li>
							<li>
								<Link
									to='/refer'
									element={<ReferEarn />}
									onClick={goToTopBtn}
									class='text-decoration-none text-white'
								>
									Refer & Earn
								</Link>
							</li>
						</ul>
					</div>
					<div class='col-md-3 col-sm-6 container my-4'>
						<h5>Blog & Insights</h5>
						<ul className='list-unstyled'>
							<li>
								<Link to='/blogs' onClick={goToTopBtn}>
									On Grid Solar
								</Link>
							</li>
							<li>
								<Link to='/blogs' onClick={goToTopBtn}>
									OFF Grid Solar
								</Link>
							</li>
							<li>
								<Link to='/blogs' onClick={goToTopBtn}>
									Hybrid Solar
								</Link>
							</li>
							<li>
								<Link to='/blogs' onClick={goToTopBtn}>
									Solar Water Pump
								</Link>
							</li>
							<li>
								<Link to='/blogs' onClick={goToTopBtn}>
									Solar Street Light
								</Link>
							</li>
						</ul>
					</div>
					<div class='col-md-3 col-sm-6 container my-4'>
						<h5>Products</h5>
						<ul className='list-unstyled'>
							<li>Solar Panels</li>
							<li>Solar Inverters</li>
							<li>Solar Battery</li>
							<li>Solar Structure</li>
							<li>EV Charging Station</li>
						</ul>
					</div>
					<div class='col-md-3 col-sm-6 container footer__logo'>
						<img src={logo} alt='' style={{ width: '100%', objectFit: 'contain' }} />
						<p className='text-white'>A Unit of advX Solution Private Limited</p>
						<Extra />
						<p className='text-white my-2 mt-3'> CIN : U93090BR2021PTC051872</p>
						<p className='text-white my-2'> GST IN : 10AAVCA1704D1Z9</p>
					</div>
				</div>
			</div>
			<hr />
			<div class='container text-light' style={{ background: '#003056' }}>
				<div class='row'>
					<div class='col-md-12 footer__footer mb-3'>
						<div>
							&copy; Copyright 2023 Sunruf-Switch To Solar. All Rights Reserved.
						</div>
						<div>
							<a href='#!' style={{ textDecoration: 'none', color: '#FFF' }}>
								Privacy Policy |
							</a>
							<a href='#!' style={{ textDecoration: 'none', color: '#FFF' }}>
								{' '}
								Terms & Conditions
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
