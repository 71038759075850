import React, { useState } from 'react';

const EditProfile = () => {
	const initialFormData = {
		fullName: 'John Doe',
		email: 'john.doe@example.com',
		mobileNumber: '1234567890',
		pincode: '123456',
		plotNo: '123',
		area: 'Example Area',
		landmark: 'Example Landmark',
		townCity: 'Example City',
		district: 'Example District',
		state: 'Bihar',
	};

	const [formData, setFormData] = useState(initialFormData);
	const [isEditMode, setIsEditMode] = useState(true);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Here you can perform the update profile action using the formData state.
		// For demonstration purposes, we will simply log the data to the console.
		console.log('Updated Profile Data:', formData);
		// You can also send the data to a server or perform other necessary actions.
		// After updating, you may want to set the form back to view mode.
		setIsEditMode(false);
	};

	const handleEditClick = () => {
		setIsEditMode(true);
	};

	const handleCancelClick = () => {
		setFormData(initialFormData);
		setIsEditMode(false);
	};

	return (
		<div className='container d-flex flex-column justify-content-center m-auto p-5'>
			<h2 className='mb-3 fw-bolder' style={{ color: '#003056' }}>
				Edit Profile
			</h2>
			{isEditMode ? (
				<form onSubmit={handleSubmit}>
					<div className='form-floating mb-3'>
						<input
							type='text'
							name='fullName'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.fullName}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							Full Name<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div className='form-floating mb-3'>
						<input
							type='email'
							name='email'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.email}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							Email Address<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div className='form-floating mb-3'>
						<input
							type='tel'
							name='mobileNumber'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.mobileNumber}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							Mobile Number<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div className='form-floating mb-3'>
						<input
							type='number'
							name='pincode'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.pincode}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							Pincode<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div className='form-floating mb-3'>
						<input
							type='text'
							name='plotNo'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.plotNo}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							Plot no, House no, Factory, Building, Apartment
							<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div className='form-floating mb-3'>
						<input
							type='text'
							name='area'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.area}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							Area, Street, Sector, Village
							<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div className='form-floating mb-3'>
						<input
							type='text'
							name='landmark'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.landmark}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							Landmark
							<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div className='form-floating mb-3'>
						<input
							type='text'
							name='townCity'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.townCity}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							Town / City
							<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div className='form-floating mb-3'>
						<input
							type='text'
							name='district'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={formData.district}
							onChange={handleChange}
							required
						/>
						<label for='floatingInput'>
							District
							<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div>
						<select
							className='form-select form-select-lg mb-3 form-control '
							aria-label='.form-select-lg example'
							type='text'
							name='state'
							value={formData.state}
							onChange={handleChange}
							required
						>
							<option selected className='form-floating'>
								Select State <b style={{ color: 'red' }}>*</b>
							</option>
							<option value='Bihar'>Bihar</option>
							<option value='Uttar Pradesh'>Uttar Pradesh</option>
							<option value='Ranchi'>Ranchi</option>
						</select>
					</div>

					{/* Add other fields as needed */}
					<button
						type='submit'
						className='p-2'
						style={{ background: '#003056', color: 'white', marginRight: '5px' }}
					>
						Update Profile
					</button>
					<button
						type='button'
						className='p-2'
						style={{ background: '#003056', color: 'white', marginRight: '5px' }}
						onClick={handleCancelClick}
					>
						Cancel
					</button>
				</form>
			) : (
				<div>
					<p>
						<strong>Full Name:</strong> {formData.fullName}
					</p>
					<p>
						<strong>Email Address:</strong> {formData.email}
					</p>
					<p>
						<strong>Mobile Number:</strong> {formData.mobileNumber}
					</p>
					<p>
						<strong>Pincode: </strong> {formData.pincode}
					</p>
					<p>
						<strong>Plot No. : </strong> {formData.plotNo}
					</p>

					<p>
						<strong>Area, Street, Sector, Village : </strong> {formData.area}
					</p>
					<p>
						<strong>Landmark : </strong> {formData.landmark}
					</p>

					<p>
						<strong>Town/City : </strong> {formData.townCity}
					</p>

					<p>
						<strong>District : </strong> {formData.district}
					</p>

					<p>
						<strong>State : </strong> {formData.state}
					</p>

					{/* Display other fields as needed */}
					<button
						type='button'
						className='p-2'
						style={{ background: '#003056', color: 'white', marginRight: '5px' }}
						onClick={handleEditClick}
					>
						Edit Profile
					</button>
				</div>
			)}
		</div>
	);
};

export default EditProfile;
