import React, { useState, createRef } from 'react';
import video1 from '../assets/video/video1.mp4';
import img1 from '../assets/video/v_img.jpg';

// video player
import { DefaultPlayer as Video } from 'react-html5video';
import HeadingComponent from './headingComponent/HeadingComponent';

const Videos = () => {
	const [model, setModel] = useState(false);
	const data = [
		{
			id: 1,
			poster: img1,
			videoUri: video1,
		},
		{
			id: 2,
			poster: img1,
			videoUri: video1,
		},
		{
			id: 3,
			poster: img1,
			videoUri: video1,
		},
	];

	return (
		<div className='container-fluid'>
			<HeadingComponent headerName={'Video Gallery'} />
			<div className='gallery'>
				{data.map((item, index) => {
					let divRef = createRef(null);
					const openModel = () => {
						divRef.current.classList.remove('video');
						divRef.current.classList.add('model');
						setModel(true);
					};
					const closeModel = () => {
						divRef.current.classList.add('video');
						divRef.current.classList.remove('model');
						setModel(false);
					};
					return (
						<div ref={divRef} className='video' key={index}>
							{model && (
								<button className='model-close-btn' onClick={() => closeModel()}>
									X
								</button>
							)}
							<div className='video-container' onClick={() => openModel()}>
								<Video
									style={{ width: '100%' }}
									autoplay={model}
									controls={['PlayPause', 'Seek', ' Time', 'Volume', 'Fullscreen']}
									poster={item.poster}
								>
									<source src={item.videoUri} type='video/webm' />
								</Video>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Videos;
