import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeBanner = ({ bgImg }) => {
	return (
		<div className='p-0 home-banner'>
			<LazyLoadImage
				src={bgImg}
				alt='homeBanner'
				style={{ width: '100%', height: '100vh' }}
			/>
		</div>
	);
};

export default HomeBanner;
