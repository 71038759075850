import React from 'react';

const ScreenLoader = () => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '100vh',
				background: '#edeff2',
			}}
		>
			<div>
				{/* <img style={{ height: '30vh' }} src={logo} alt='preloader logo' /> */}
				<iframe
					title='ifra'
					src='https://giphy.com/embed/jAYUbVXgESSti'
					width='480'
					height='270'
					class='giphy-embed'
					background='#003'
					allowFullScreen
				></iframe>
				{/* <p>
					<a href='https://giphy.com/gifs/google-icon-loading-jAYUbVXgESSti'>
						via GIPHY
					</a>
				</p> */}
				{/* <h1
					style={{
						fontFamily: 'RegularCalson',
						textAlign: 'center',
						color: 'white',
					}}
				>
					Sunruf: Switch to Solar
				</h1> */}
			</div>
		</div>
	);
};

export default ScreenLoader;
