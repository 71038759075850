import React from 'react';
import './ReferandEarn.css';

import img1 from '../../assets/banner/refer.jpg';
import { Link } from 'react-router-dom';

const goToTopBtn = () => {
	window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
};

const ReferAndEarn = () => {
	return (
		<div class='card bg-dark text-white referandearn_div'>
			<img src={img1} class='card-img' alt='...' />
			<div class='card-img-overlay referandearn'>
				<h2 class='card-title'>Refer and Earn</h2>
				<p class='card-text'>
					To install Rooftop Solar Recommend SunRuf – Switch To Solar to Your Friends
					&amp; Families or any individuals and &amp; Earn in Lakhs per Month Without
					any Investment.
				</p>
				<p class='card-text '>
					How Its Works
					<ul>
						<li>Join ( Register yourself with us )</li>
						<li>Refer ( Recommend SunRuf – Switch To Solar )</li>
						<li>Earn ( Get Reward for Every Single Referral )</li>
					</ul>
				</p>
				<button
					style={{
						display: 'flex',
						padding: '10px 20px',
						background: '#003056',
						color: '#fff',
					}}
				>
					<Link
						to='/refer'
						// element={<About />}
						onClick={() => {
							goToTopBtn();
						}}
					>
						Join Us Now
					</Link>
				</button>
			</div>
		</div>
	);
};

export default ReferAndEarn;
