import React from 'react';
import '../components/footer/Footer.css';

import Google_Logo from '../assets/social_logos/g.png';
import Facebook_Logo from '../assets/social_logos/f.png';
import Instagram_Logo from '../assets/social_logos/i.png';
import Youtube_Logo from '../assets/social_logos/y.png';
import X_Logo from '../assets/social_logos/x.png';
import Linkdlin_Logo from '../assets/social_logos/l.png';

const Extra = () => {
	return (
		<div>
			<div className='social_links d-flex justify-content-left align-items-left'>
				<li className='list-styled-none'>
					<a href='https://goo.gl/maps/PxxrGDBrdpNc3kRJ8' target='blank'>
						<img src={Google_Logo} alt='' style={{ objectFit: 'contain' }} />
					</a>
					<a href='https://www.facebook.com/hellosunruf' target='blank'>
						<img src={Facebook_Logo} alt='' />
					</a>
					<a href='https://www.instagram.com/hellosunruf' target='blank'>
						<img src={Instagram_Logo} alt='' />
					</a>
					<a href='https://www.youtube.com/@hellosunruf' target='blank'>
						<img src={Youtube_Logo} alt='' />
					</a>
					<a href='https://twitter.com/hellosunruf' target='blank'>
						<img src={X_Logo} alt='' />
					</a>
					<a href='https://www.linkedin.com/company/hellosunruf/' target='blank'>
						<img src={Linkdlin_Logo} alt='' />
					</a>
				</li>
			</div>
		</div>
	);
};

export default Extra;
