import './App.css';
import React, { useEffect, useState } from 'react';

import img3 from './assets/banner/home-banner-3.jpg';

import { HashRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';

import Footer from './components/footer/Footer';
import Contact from './pages/contact/Contact';
import About from './pages/about/About';
import Herosection from './components/herosection/Herosection';
// import WantToWork from './components/wanttowork/WantToWork';
import MarQuee from './components/MarQuee';
import SearchByMenu from './components/SearchByMenu';

import RoofTopSolorSolutions from './components/RoofTopSolorSolutions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomeBanner from './components/homeBanner/HomeBanner';
import Testimonials from './components/Testimonials';
import Videos from './components/Videos';
import 'react-html5video/dist/styles.css';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import Profile from './pages/profile/Profile';
import EditProfile from './pages/profile/EditProfile';
import ReferEarn from './pages/profile/ReferEarn';
import YourQuery from './pages/profile/YourQuery';
import SiteSurvey from './components/searchByMenuPages/SiteSurvey';
import SolarExpert from './components/searchByMenuPages/SolarExpert';
import CenterLocator from './components/searchByMenuPages/CenterLocator';
import SolarEntrepreneur from './components/searchByMenuPages/SolarEntrepreneur';
import RoofTop from './components/searchByMenuPages/RoofTop';
import Blog from './pages/blog/Blog';
import KnowledgeCenter from './components/KnowledgeCenter';
import ApprovedBy from './components/AprrovedBy';
import SolarSystemGrid from './components/SolarSystemGrid';
import WhatsAppChat from './components/onScreenButtons/WhatsAppChat';
import SolarCalculator from './components/onScreenButtons/SolarCalculator';
import KnowledgePage from './components/knowledgePage/KnowledgePage';
import WhySunruf from './components/whySunruf/WhySunruf';
import ScreenLoader from './components/screenLoader/ScreenLoader';
import ReferAndEarn from './components/referAndEarn/ReferAndEarn';
import SolarMart from './components/searchByMenuPages/SolarMart';

function App() {
	const [knowledgeID, setKnowledgeID] = useState();
	const [selectedID, setSelectedId] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => setLoading(false), 1000);
		window.scrollTo(0, 0);
	});

	if (loading) return <ScreenLoader />;

	return (
		<Router>
			<Navbar />

			<Routes>
				<Route
					path='/'
					element={
						<>
							<Herosection />
							<SearchByMenu />
							<ApprovedBy />
							<SolarSystemGrid setSelectedId={setSelectedId} />
							<MarQuee />
							<WhySunruf />
							<SolarMart />
							{/* <WantToWork /> */}
							<RoofTopSolorSolutions />
							<ReferAndEarn />
							{/* <Service /> */}
							<Testimonials />
							<HomeBanner bgImg={img3} />
							<Videos />
							<KnowledgeCenter setKnowledgeID={setKnowledgeID} />
						</>
					}
				/>
				<Route path='/contact' element={<Contact />} />
				<Route path='/about' element={<About />} />
				<Route path='/blogs' element={<Blog selectedID={selectedID} />} />
				<Route path='/signin' element={<SignIn />} />
				<Route path='/signup' element={<SignUp />} />
				<Route path='/profile' element={<Profile />} />
				<Route path='/editProfile' element={<EditProfile />} />
				<Route path='/refer' element={<ReferEarn />} />
				<Route path='/yourEnquiry' element={<YourQuery />} />
				<Route path='/forgotPassword' element={<ForgotPassword />} />

				{/* Search By Menu Pages */}

				<Route path='/survey' element={<SiteSurvey />} />
				<Route path='/solarExpert' element={<SolarExpert />} />
				<Route path='/centerLocator' element={<CenterLocator />} />
				<Route path='/solarEntrepreneur' element={<SolarEntrepreneur />} />
				<Route path='/rooftop' element={<RoofTop />} />
				<Route path='/solarMart' element={<SolarMart />} />

				<Route
					path='/knowledge'
					element={<KnowledgePage knowledgeID={knowledgeID} />}
				/>
			</Routes>

			<SolarCalculator />
			<WhatsAppChat />
			{/* <GoTop /> */}
			<Footer />
		</Router>
	);
}

export default App;
