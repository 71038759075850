import React from 'react';
import img1 from '../../assets/solarMart/1.png';
import img2 from '../../assets/solarMart/2.png';
import img3 from '../../assets/solarMart/3.png';
import img4 from '../../assets/solarMart/4.png';
import img5 from '../../assets/solarMart/5.png';
import HeadingComponent from '../headingComponent/HeadingComponent';
import Slider from 'react-slick';

import './SearchByMenuPages.css';

const solarMartData = [
	{
		id: 1,
		image: img1,
		title: 'EV Charger',
	},
	{
		id: 2,
		image: img2,
		title: 'Solar Battery',
	},
	{
		id: 3,
		image: img3,
		title: 'Solar Inverter',
	},
	{
		id: 4,
		image: img4,
		title: 'Solar Panel',
	},
	{
		id: 5,
		image: img5,
		title: 'Solar Structure',
	},
];

const SolarMart = () => {
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 1500,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<>
			<HeadingComponent headerName={'Solar Mart'} />
			<div className='solarMart-page'>
				<Slider {...settings}>
					{solarMartData.map((item) => (
						<div className='solarMart-content' key={item.id}>
							<div className='card'>
								<div className='card-img-top'>
									<img src={item.image} alt='' />
								</div>
								<div className='card-body'>
									<div className='card-text text-center'>{item.title}</div>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>
			<hr />
		</>
	);
};

export default SolarMart;
