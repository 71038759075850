import React from 'react';
import styled from '@emotion/styled';
import WhatApp from '../../assets/social_logos/w.png';

const WhatsAppChat = () => {
	return (
		<Wrapper>
			<div className='top-btn-w'>
				<a
					href='https://wa.me/918210819658?text=Hello Team Sunruf, may I request a callback, please?'
					target='_blank'
					rel='noreferrer'
				>
					<img src={WhatApp} alt='' className='top-btn--icon' />
				</a>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;

	.top-btn-w {
		font-size: 3rem;
		width: 3rem;
		height: 3rem;

		box-shadow: #00182b;
		border-radius: 50%;
		position: fixed;
		bottom: 1.5rem;
		right: 2rem;

		z-index: 999;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.top-btn-w img {
		height: 64px;
		border-radius: 30%;
	}
`;

export default WhatsAppChat;
