import React, { useState, useRef } from 'react';
import Banner from '../../components/banner/Banner';
import img1 from '../../assets/banner/1.jpg';
import './SearchByMenuPages.css';
import emailjs from '@emailjs/browser';

const SiteSurvey = () => {
	const form = useRef();
	const [formData, setFormData] = useState({
		fullName: '',
		mobileNumber: '',
		email: '',
		subject: '',
		message: '',
	});
	const [errors, setErrors] = useState({
		fullName: '',
		mobileNumber: '',
		email: '',
		subject: '',
		message: '',
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
		// Clear the corresponding error when the user starts typing
		setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
	};

	const handleRadioChange = (e) => {
		setFormData((prevData) => ({ ...prevData, subject: e.target.value }));
		// Clear the subject error when a subject is selected
		setErrors((prevErrors) => ({ ...prevErrors, subject: '' }));
	};

	const isAlphabetsOnly = (input) => /^[A-Za-z ]*$/.test(input);

	const isElevenDigits = (input) => /^\d{10}$/.test(input);

	const isValidEmail = (input) => /\S+@\S+\.\S+/.test(input);

	const isDigitOnly = (input) => /^\d+$/.test(input);

	const sendMessage = async (e) => {
		e.preventDefault();

		const { fullName, mobileNumber, email, subject, message } = formData;

		// Update the state with validation errors
		const newErrors = {
			fullName:
				!fullName || !isAlphabetsOnly(fullName)
					? 'Please provide a valid full name'
					: '',
			mobileNumber:
				!mobileNumber || !isElevenDigits(mobileNumber)
					? 'Please provide a valid 10-digit mobile number'
					: '',
			email:
				!email || !isValidEmail(email) ? 'Please enter a valid email address' : '',
			subject: !subject ? 'Please select a subject' : '',
			message:
				!message || !isDigitOnly(message)
					? 'Electricity Bill Should be Digit Only'
					: '',
		};

		setErrors(newErrors);

		// Check if there are any validation errors
		if (Object.values(newErrors).some((error) => error !== '')) {
			return;
		}

		try {
			const result = await emailjs.sendForm(
				'service_oojzrmk', // Contact Solar Service Id
				'template_vmdg7wf', // Contact Solar Template Id
				form.current,
				'LHV8VFNMM0kWV5FaK' // Contact Solar Public Key
			);

			const isFormSubmittedSuccessfully = result.status === 200;
			if (isFormSubmittedSuccessfully) {
				// You can use a library like react-bootstrap, react-modal, or a custom solution for a modal/dialog
				alert('Form submitted successfully. We will get back to you very soon.');

				// Reset form data and errors after successful submission
				setFormData({
					fullName: '',
					mobileNumber: '',
					email: '',
					subject: '',
					message: '',
				});
				setErrors({
					fullName: '',
					mobileNumber: '',
					email: '',
					subject: '',
					message: '',
				});
			} else {
				// Handle form submission failure (optional)
				alert('Form submission failed. Please try again later.');
			}
		} catch (error) {
			console.error(error.text);
			// Handle other errors that may occur during form submission
			alert('An unexpected error occurred. Please try again later.');
		}
	};

	return (
		<div className='container-flui'>
			<Banner
				bgImg={img1}
				title={'Book Site Visit'}
				para={'“Discover Solar Wonders: Step Inside Sunruf World”'}
			/>
			<div className='container'>
				<form ref={form}>
					<section>
						<h6 className='my-5'>
							Solar Power System For<b style={{ color: 'red' }}>*</b>:
						</h6>

						<div className='solar_services'>
							{[
								{ label: 'Home', value: 'Home' },
								{ label: 'Housing Society', value: 'Housing Society' },
								{ label: 'Commercial & Industrial', value: 'Commercial & Industrial' },
								{ label: 'EV Charging Station', value: 'EV Charging Station' },
							].map((option, index) => (
								<div key={index} className='form-check col-md-3 mb-4'>
									<input
										className='form-check-input'
										type='radio'
										name='subject'
										id={option.value}
										value={option.value}
										onChange={handleRadioChange}
										checked={formData.subject === option.value}
									/>
									<label className='form-check-label' htmlFor={option.value}>
										{option.label}
									</label>
								</div>
							))}
						</div>
					</section>

					{/* form */}

					{['fullName', 'mobileNumber', 'email', 'message'].map((field, index) => (
						<div key={index} className='form-floating mb-3'>
							<input
								type={field === 'mobileNumber' ? 'tel' : 'text'}
								className='form-control'
								id={field}
								name={field}
								placeholder={
									field === 'message' ? 'Your Average Monthly Electricity Bill' : field
								}
								value={formData[field]}
								onChange={handleInputChange}
								required
							/>
							<label htmlFor={field}>
								{field === 'fullName'
									? 'Full Name'
									: field === 'mobileNumber'
									? 'Mobile Number'
									: field === 'email'
									? 'Email Address'
									: field === 'message'
									? 'Your Average Monthly Electricity Bill'
									: ''}
								<b style={{ color: 'red' }}>*</b>
							</label>
							{field === 'fullName' && (
								<div className='valid-feedback'>Looks good!</div>
							)}
							{/* Display error message for the current field */}
							<div className='text-danger'>{errors[field]}</div>
						</div>
					))}

					<div className='form-check'>
						<input
							className='form-check-input'
							name='flexRadioDefault'
							type='checkbox'
							value=''
							id='flexCheckIndeterminate'
						/>
						<label className='form-check-label' htmlFor='flexCheckIndeterminate'>
							I agree to SunRuf - Switch To Solar's terms of service & privacy policy
						</label>
					</div>

					{/* Display error messages */}
					<div className='error-messages'>
						{Object.values(errors).map(
							(error, index) =>
								error && (
									<p key={index} className='text-danger'>
										{error}
									</p>
								)
						)}
					</div>

					<p className='my-3 text-muted text-center'>
						<button
							className='p-2 w-50'
							style={{ background: '#003056', color: 'white' }}
							onClick={sendMessage}
						>
							Book Now
						</button>
					</p>
				</form>
			</div>
		</div>
	);
};

export default SiteSurvey;
