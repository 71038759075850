import React from 'react';
import Slider from 'react-slick';
import HeadingComponent from './headingComponent/HeadingComponent';
import { useNavigate } from 'react-router-dom';
// import solarImages from '../assets/solarSolutionsGrid/';

import ss1 from '../assets/solarSolutionsGrid/3.avif';
import ss2 from '../assets/solarSolutionsGrid/4.jpeg';
import ss3 from '../assets/solarSolutionsGrid/5.jpg';
import ss4 from '../assets/solarSolutionsGrid/7.jpg';
import ss5 from '../assets/solarSolutionsGrid/SOLAR MILL.png';
import ss6 from '../assets/solarSolutionsGrid/SOLAR WATER PUMP.jpg';
import ss7 from '../assets/solarSolutionsGrid/1.webp';
import ss8 from '../assets/solarSolutionsGrid/2.webp';

const SolarSystemGrid = ({ setSelectedId }) => {
	const SampleArrow = ({ ssgNext, style, onClick }) => (
		<div
			className={ssgNext}
			style={{
				...style,
				display: 'block',
				background: '#003056',
				borderRadius: '50%',
			}}
			onClick={onClick}
		/>
	);

	const SampleBArrow = ({ spBefore, style, onClick }) => (
		<div
			className={spBefore}
			style={{
				...style,
				display: 'block',
				background: '#003056',
				borderRadius: '50%',
			}}
			onClick={onClick}
		/>
	);
	const navigate = useNavigate();

	const sliderSettings = {
		dots: false,
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 1500,
		cssEase: 'linear',
		nextArrow: <SampleArrow ssgNext />,
		prevArrow: <SampleBArrow sspBefore />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const goToTopBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	};

	const slides = [
		{ id: 1, imgSrc: ss1, title: 'OFF Grid Solar' },
		{ id: 2, imgSrc: ss2, title: 'ON Grid Solar' },
		{ id: 3, imgSrc: ss3, title: 'Hybrid Solar' },
		{ id: 4, imgSrc: ss4, title: 'EV Charging Station' },
		{ id: 5, imgSrc: ss5, title: 'Oil, Flour & Masala Mill' },
		{ id: 6, imgSrc: ss6, title: 'Solar Water Pump' },
		{ id: 7, imgSrc: ss7, title: 'Solar Street Light' },
		{ id: 8, imgSrc: ss8, title: 'Solar Water Heater' },
	];

	return (
		<div className='container-fluid'>
			<HeadingComponent headerName={'Solar Power System'} />
			<div className='container'>
				<Slider {...sliderSettings}>
					{slides.map((slide) => (
						<div key={slide.id} className='main-div'>
							<div className='card text-white'>
								<img
									src={slide.imgSrc}
									className='card-img'
									alt='solarGridImages'
									style={{ height: '400px', objectFit: 'cover', background: '#003056' }}
								/>
								<div
									className='card-img-overlay'
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'end',
										alignItems: 'center',
									}}
								>
									<h5 className='card-title display-6 fw-bold'>{slide.title}</h5>
									<div className='gap-2 col-md-3 d-grid col-sm-3'>
										<button
											className='btn'
											type='button'
											onClick={() => {
												navigate('/blogs');
												goToTopBtn();
												setSelectedId(slide.id);
											}}
											style={{
												backgroundColor: '#003056',
												color: 'white',
												padding: '1rem',
												border: '1px solid white',
											}}
										>
											Learn More
										</button>
									</div>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default SolarSystemGrid;
