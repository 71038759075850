import React from 'react';

const ForgotPassword = () => {
	return (
		<div>
			<h1 className='text-center vh-100 d-flex justify-content-center align-items-center'>
				Forgot Password. No design Assigned Yet.
			</h1>
		</div>
	);
};

export default ForgotPassword;
