import React, { useState, useRef } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';

const Contact = () => {
	const form = useRef();
	const [formData, setFormData] = useState({
		fullName: '',
		mobileNumber: '',
		email: '',
		message: '',
	});

	const [errors, setErrors] = useState({
		fullName: '',
		mobileNumber: '',
		email: '',
		message: '',
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
		setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
	};

	const isAlphabetsOnly = (input) => /^[A-Za-z ]*$/.test(input);
	const isElevenDigits = (input) => /^\d{10}$/.test(input);
	const isValidEmail = (input) => /\S+@\S+\.\S+/.test(input);

	const sendMessage = async (e) => {
		e.preventDefault();

		const { fullName, mobileNumber, email, message } = formData;

		// Update the state with validation errors
		const newErrors = {
			fullName:
				!fullName || !isAlphabetsOnly(fullName)
					? 'Please provide a valid full name'
					: '',
			mobileNumber:
				!mobileNumber || !isElevenDigits(mobileNumber)
					? 'Please provide a valid 10-digit mobile number'
					: '',
			email:
				!email || !isValidEmail(email) ? 'Please enter a valid email address' : '',
			message: !message ? 'Please enter a message' : '',
		};

		setErrors(newErrors);

		// Check if there are any validation errors
		if (Object.values(newErrors).some((error) => error !== '')) {
			return;
		}

		try {
			const result = await emailjs.sendForm(
				'service_78xi90p',
				'template_3uuqiir',
				form.current,
				'LHV8VFNMM0kWV5FaK'
			);

			console.log(result.text);
			alert('Form submitted successfully. We will get back to you very soon.');

			// Reset form data and errors after successful submission
			setFormData({
				fullName: '',
				mobileNumber: '',
				email: '',
				message: '',
			});

			setErrors({
				fullName: '',
				mobileNumber: '',
				email: '',
				message: '',
			});
		} catch (error) {
			console.error(error.text);
		}
	};

	return (
		<section>
			<div className='contact'>
				<div className='content'>
					<h2>Contact Us</h2>
					<h6 className='text-white'>
						"Shine a Light on Your Questions: Reach Out to Sunruf!"
					</h6>
				</div>
				<div className='container_class'>
					<div className='contactInfo'>
						<div
							className='box'
							style={{
								background: '#fff',
								opacity: '0.6',
								margin: 'auto',
							}}
						>
							<div className='icon' style={{ marginLeft: '15px' }}>
								<i class='bi bi-geo-alt'></i>
							</div>
							<div className='text'>
								<h3>Address</h3>
								<p>
									Sahu Colony, Meena Bazar, Patna, Bihar <br /> India - 800007
								</p>
							</div>
						</div>
						<div
							className='box'
							style={{
								background: '#fff',
								width: '75%',
								opacity: '0.6',
								margin: 'auto',
							}}
						>
							<div className='icon' style={{ marginLeft: '15px' }}>
								<i class='bi bi-telephone'></i>
							</div>
							<div className='text'>
								<h3>Phone</h3>
								<p>+91-82108 19658</p>
							</div>
						</div>
						<div
							className='box'
							style={{
								background: '#fff',
								width: '75%',
								opacity: '0.6',
								margin: 'auto',
							}}
						>
							<div className='icon' style={{ marginLeft: '15px' }}>
								<i class='bi bi-envelope'></i>
							</div>
							<div className='text'>
								<h3>Email</h3>
								<p>switchtosolar@sunruf.com</p>
							</div>
						</div>
					</div>
					<div className='contactForm'>
						<form ref={form}>
							<h2>Send Message</h2>

							<div className='inputBox'>
								<input
									type='text'
									name='fullName'
									required='required'
									value={formData.fullName}
									onChange={handleInputChange}
									pattern='[A-Za-z ]+'
								/>
								<span>Full Name</span>
								{/* Display error message for the current field */}
								<div className='text-danger'>{errors.fullName}</div>
							</div>

							<div className='inputBox'>
								<input
									type='text'
									name='mobileNumber'
									required='required'
									value={formData.mobileNumber}
									onChange={handleInputChange}
									// pattern='[0-9]{10}'
								/>
								<span>Phone</span>
								{/* Display error message for the current field */}
								<div className='text-danger'>{errors.mobileNumber}</div>
							</div>

							<div className='inputBox'>
								<input
									type='text'
									name='email'
									required='required'
									value={formData.email}
									onChange={handleInputChange}
								/>
								<span>Email</span>
								{/* Display error message for the current field */}
								<div className='text-danger'>{errors.email}</div>
							</div>

							<div className='inputBox'>
								<textarea
									name='message'
									required='required'
									value={formData.message}
									onChange={handleInputChange}
								></textarea>
								<span>Type your message...</span>
								{/* Display error message for the current field */}
								<div className='text-danger'>{errors.message}</div>
							</div>

							<div className='inputBox'>
								<input type='submit' value='Submit Request' onClick={sendMessage} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
