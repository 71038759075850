import React, { useState } from 'react';
import './Profile.css';

const ReferEarn = () => {
	const [fullName, setFullName] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');

	const handleFullNameChange = (e) => {
		const re = /^[a-zA-Z ]*$/;
		if (re.test(e.target.value) || e.target.value === '') {
			setFullName(e.target.value);
		}
	};

	const handleMobileNumberChange = (e) => {
		const re = /^[0-9]{0,10}$/;
		if (re.test(e.target.value) || e.target.value === '') {
			setMobileNumber(e.target.value);
		}
	};

	return (
		<div>
			<main className='container d-flex flex-column justify-content-center'>
				<h3 class='my-5 fw-bolder text-center' style={{ color: '#003056' }}>
					Create New Lead
				</h3>
				<section>
					<h6 className='mb-4'>
						Services<b style={{ color: 'red' }}>*</b>
					</h6>
					<div>
						<div className='service_refer mb-lg-5'>
							<div class='form-check col-md-4'>
								<input
									class='form-check-input'
									type='checkbox'
									value=''
									id='flexCheckIndeterminate'
								/>
								<label class='form-check-label' for='flexCheckIndeterminate'>
									Solar Rooftop
								</label>
							</div>
							<div class='form-check  col-md-4'>
								<input
									class='form-check-input'
									type='checkbox'
									value=''
									id='flexCheckIndeterminate'
								/>
								<label class='form-check-label' for='flexCheckIndeterminate'>
									Solar Cleaning
								</label>
							</div>
							<div class='form-check col-md-4'>
								<input
									class='form-check-input'
									type='checkbox'
									value=''
									id='flexCheckIndeterminate'
								/>
								<label class='form-check-label' for='flexCheckIndeterminate'>
									Solar AMC
								</label>
							</div>
						</div>
						<div className='service_refer mb-5'>
							<div class='form-check col-md-4'>
								<input
									class='form-check-input'
									type='checkbox'
									value=''
									id='flexCheckIndeterminate'
								/>
								<label class='form-check-label' for='flexCheckIndeterminate'>
									Solar Loan
								</label>
							</div>
							<div class='form-check col-md-4'>
								<input
									class='form-check-input'
									type='checkbox'
									value=''
									id='flexCheckIndeterminate'
								/>
								<label class='form-check-label' for='flexCheckIndeterminate'>
									Solar Insurance
								</label>
							</div>
							<div class='form-check col-md-4'>
								<input
									class='form-check-input'
									type='checkbox'
									value=''
									id='flexCheckIndeterminate'
								/>
								<label class='form-check-label' for='flexCheckIndeterminate'>
									Solar Visit
								</label>
							</div>
						</div>
					</div>
				</section>
				<form>
					<div class='form-floating mb-3'>
						<input
							type='name'
							class='form-control'
							id='floatingInput'
							placeholder='Full Name'
							value={fullName}
							onChange={handleFullNameChange}
						/>
						<label for='floatingInput'>
							Customer's Name<b style={{ color: 'red' }}>*</b>
						</label>
					</div>
					<div class='form-floating mb-3'>
						<input
							type='email'
							class='form-control'
							id='floatingInput'
							placeholder='Email Address'
						/>
						<label for='floatingInput'>
							Email Address<b style={{ color: 'red' }}>*</b>
						</label>
					</div>
					<div class='form-floating mb-3'>
						<input
							type='string'
							class='form-control'
							id='floatingInput'
							placeholder='name@example.com'
							value={mobileNumber}
							onChange={handleMobileNumberChange}
						/>
						<label for='floatingInput'>
							Mobile Number<b style={{ color: 'red' }}>*</b>
						</label>
					</div>
					<div class='form-floating mb-3'>
						<textarea
							class='form-control'
							placeholder='Leave a comment here'
							id='floatingTextarea'
							style={{ height: '100px' }}
						></textarea>
						<label for='floatingPassword'>
							Type Your Message Here . . .<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<div class='form-floating mb-3'>
						<input
							type='text'
							class='form-control'
							id='floatingInput'
							placeholder='Refer Code'
						/>
						<label for='floatingInput'>
							Refer Code<b style={{ color: 'red' }}>*</b>
						</label>
					</div>

					<p class='my-3 text-muted text-center'>
						<button
							className='p-2 w-50'
							style={{ background: '#003056', color: 'white' }}
						>
							Submit
						</button>
					</p>
				</form>
			</main>
		</div>
	);
};

export default ReferEarn;
