import React, { useState, useRef } from 'react';
import Banner from '../banner/Banner';
import img1 from '../../assets/banner/b5.jpg';
import './SearchByMenuPages.css';
import emailjs from '@emailjs/browser';

const SolarExpert = () => {
	const form = useRef();
	const [formData, setFormData] = useState({
		fullName: '',
		mobileNumber: '',
		email: '',
		message: '',
	});

	const [errors, setErrors] = useState({
		fullName: '',
		mobileNumber: '',
		email: '',
		message: '',
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
		setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
	};

	const isAlphabetsOnly = (input) => /^[A-Za-z ]*$/.test(input);
	const isElevenDigits = (input) => /^\d{10}$/.test(input);
	const isValidEmail = (input) => /\S+@\S+\.\S+/.test(input);

	const sendMessage = async (e) => {
		e.preventDefault();

		const { fullName, mobileNumber, email, message } = formData;

		// Update the state with validation errors
		const newErrors = {
			fullName:
				!fullName || !isAlphabetsOnly(fullName)
					? 'Please provide a valid full name'
					: '',
			mobileNumber:
				!mobileNumber || !isElevenDigits(mobileNumber)
					? 'Please provide a valid 10-digit mobile number'
					: '',
			email:
				!email || !isValidEmail(email) ? 'Please enter a valid email address' : '',
			message: !message ? 'Please enter a message' : '',
		};

		setErrors(newErrors);

		// Check if there are any validation errors
		if (Object.values(newErrors).some((error) => error !== '')) {
			return;
		}

		try {
			const result = await emailjs.sendForm(
				'service_78xi90p',
				'template_3uuqiir',
				form.current,
				'LHV8VFNMM0kWV5FaK'
			);

			console.log(result.text);
			alert('Form submitted successfully. We will get back to you very soon.');

			// Reset form data and errors after successful submission
			setFormData({
				fullName: '',
				mobileNumber: '',
				email: '',
				message: '',
			});

			setErrors({
				fullName: '',
				mobileNumber: '',
				email: '',
				message: '',
			});
		} catch (error) {
			console.error(error.text);
		}
	};

	return (
		<div className='solar_expert'>
			<Banner
				bgImg={img1}
				title={'Solar Expert'}
				para={'Schedule A Callback with Our Solar Expert'}
			/>
			<div className='container my-5'>
				<h3 className='mb-3'>
					A Solar Expert is a knowledgeable professional well-versed in solar energy
				</h3>
				<p
					className='card-text'
					style={{
						textAlign: 'justify',
						marginBottom: '1.5rem',
						fontSize: '18px',
						color: '#212121',
					}}
				>
					A Solar Expert is a knowledgeable professional well-versed in solar energy
					technology and its applications. They possess expertise in designing,
					installing, and maintaining solar systems for various purposes, like
					residential, commercial, and industrial settings. These experts are skilled
					in assessing energy needs, conducting feasibility studies, and recommending
					appropriate solar solutions. Additionally, they stay updated with the
					latest advancements in solar technology and regulations. A Solar Expert
					plays a crucial role in promoting sustainable energy practices, reducing
					carbon footprint, and fostering a greener future. Their profound
					understanding of solar power makes them instrumental in harnessing the
					sun’s potential to meet the world's energy demands efficiently.
				</p>
				<form ref={form}>
					<div className='form-floating mb-3'>
						<input
							type='text'
							className='form-control'
							id='fullName'
							name='fullName'
							placeholder='Full Name'
							value={formData.fullName}
							onChange={handleInputChange}
							pattern='[A-Za-z ]+'
							title='Please enter only alphabets'
							required
						/>
						<label htmlFor='fullName'>
							Full Name<b style={{ color: 'red' }}>*</b>
						</label>
						<div className='text-danger'>{errors.fullName}</div>
					</div>
					<div className='form-floating mb-3'>
						<input
							type='text'
							className='form-control'
							id='mobileNumber'
							name='mobileNumber'
							placeholder='Fill up your mobile number'
							value={formData.mobileNumber}
							onChange={handleInputChange}
							pattern='[0-9]{11}'
							title='Please enter 11 digits'
							required
						/>
						<label htmlFor='mobileNumber'>
							Mobile Number<b style={{ color: 'red' }}>*</b>
						</label>
						<div className='text-danger'>{errors.mobileNumber}</div>
					</div>
					<div className='form-floating mb-3'>
						<input
							type='email'
							className='form-control'
							id='email'
							name='email'
							placeholder='Email Address'
							value={formData.email}
							onChange={handleInputChange}
						/>
						<label htmlFor='email'>
							Email Address<b style={{ color: 'red' }}>*</b>
						</label>
						<div className='text-danger'>{errors.email}</div>
					</div>
					<div className='form-floating mb-3'>
						<textarea
							className='form-control'
							placeholder='Leave a comment here'
							id='message'
							name='message'
							style={{ height: '120px' }}
							value={formData.message}
							onChange={handleInputChange}
						></textarea>
						<label htmlFor='message'>
							Type Your Message Here . . .<b style={{ color: 'red' }}>*</b>
						</label>
						<div className='text-danger'>{errors.message}</div>
					</div>
					<p className='mt-3 mb-2 text-muted'>
						<button
							className='p-2'
							style={{ background: '#003056', color: 'white' }}
							onClick={sendMessage}
						>
							Request a Call Back
						</button>
					</p>
				</form>
			</div>
		</div>
	);
};

export default SolarExpert;
