import React from 'react';
import Banner from '../banner/Banner';
import './SearchByMenuPages.css';

import img1 from '../../assets/banner/ssb.jpg';
import RoofTopSolorSolutions from '../RoofTopSolorSolutions';

const RoofTop = () => {
	return (
		<div>
			<Banner
				bgImg={img1}
				title={'Sustainable Energy, Seamless Solutions: Sunruf Solar'}
			/>
			<div className='container-fluid'>
				<RoofTopSolorSolutions />
			</div>
		</div>
	);
};

export default RoofTop;
