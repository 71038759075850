import React from 'react';
import './KnowledgePage.css';

const RightSubKnowledge = ({ cards, onCardClick }) => {
	const goToTopBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	};

	return (
		<div className='sub_blog_container p-4'>
			<h2 className='mb-3'>Releated Blog</h2>
			{cards.map((card) => (
				<div
					key={card.id}
					className='card mb-3 mb-lg-3 mb-sm-3 mb-md-3'
					onClick={() => {
						onCardClick(card);
						goToTopBtn();
					}}
				>
					<img src={card.image} alt='' class='card-img-top' />
					<hr style={{ width: '100%', margin: '10px auto' }} />
					<h5 className='mx-3'>{card.title}</h5>
					<p className='mx-3'>{card.subtitle}</p>
				</div>
			))}
		</div>
	);
};

export default RightSubKnowledge;
