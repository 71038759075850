import React, { useState } from 'react';
import Banner from '../banner/Banner';
import img1 from '../../assets/banner/b1.jpg';
import './SearchByMenuPages.css';

const SolarEntrepreneur = () => {
	const [selectedDistrict, setSelectedDistrict] = useState('');
	const [fullName, setFullName] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [selectedProfession, setSelectedProfession] = useState('');

	const handleChange = (e) => {
		setSelectedDistrict(e.target.value);
	};

	const handleInputChange = (setStateFunction, regex, value) => {
		const re = regex;
		if (re.test(value) || value === '') {
			setStateFunction(value);
		}
	};

	const districtOptions = [
		'Araria',
		'Arwal',
		'Aurangabad',
		'Banka',
		'Begusarai',
		'Bhagalpur',
		'Bhojpur',
		'Buxar',
		'Darbhanga',
		'East Champaran',
		'Gaya',
		'Gopalganj',
		'Jamui',
		'Jehanabad',
		'Kaimur',
		'Katihar',
		'Khagaria',
		'Kishanganj',
		'Lakhisarai',
		'Madhepura',
		'Madhubani',
		'Munger',
		'Muzaffarpur',
		'Nalanda',
		'Nawada',
		'Patna',
		'Purnia',
		'Rohtas',
		'Saharsa',
		'Samastipur',
		'Saran',
		'Sheikhpura',
		'Sheohar',
		'Sitamarhi',
		'Siwan',
		'Supaul',
		'Vaishali',
		'West Champaran',
	];

	const professionOptions = [
		'Please Select Your Profession',
		'SunRuf - Switch To Solar Existing Customer',
		'Solar Rooftop Owner',
		'Architect / Developer / Interior',
		'Environmentalist / Activist',
		'Government Employee',
		'Self Employed',
		'Business Owner',
		'Freelancer',
		'NGO / Charitable Trust',
		'Market Researcher',
		'Social Media Influencer',
		'Vlogger',
		'Solar Energy Promoter',
		'Content Writer /Bloggers',
		'Online / Offline Marketing Agency',
		'Video Content Creator / Youtuber',
		'Digital Marketing / PR Agency',
		'Other',
	];

	return (
		<div>
			<Banner
				bgImg={img1}
				title={'Start Your Solar Business with Zero Investment'}
			/>
			<div className='container my-5'>
				<div>
					<label htmlFor='professionSelect' className='form-label'>
						Select Your Profession<b style={{ color: 'red' }}>*</b>
					</label>
					<select
						id='professionSelect'
						className='form-select'
						aria-label='Default select example'
						value={selectedProfession}
						onChange={(e) => setSelectedProfession(e.target.value)}
					>
						{professionOptions.map((option, index) => (
							<option key={index} value={option} disabled={index === 0}>
								{option}
							</option>
						))}
					</select>
				</div>
				<div className='my-3'>
					<label htmlFor='fullNameInput' className='form-label'>
						Full Name<b style={{ color: 'red' }}>*</b>
					</label>
					<input
						type='text'
						id='fullNameInput'
						name='fullname'
						value={fullName}
						onChange={(e) =>
							handleInputChange(setFullName, /^[a-zA-Z ]*$/, e.target.value)
						}
						className='form-control'
						placeholder='Full Name'
					/>
				</div>
				<div className='my-3'>
					<label htmlFor='mobileNumberInput' className='form-label'>
						Contact Number<b style={{ color: 'red' }}>*</b>
					</label>
					<input
						type='tel'
						id='mobileNumberInput'
						name='number'
						className='form-control'
						placeholder='Type 10 digit Mobile Number'
						value={mobileNumber}
						onChange={(e) =>
							handleInputChange(setMobileNumber, /^[0-9]{0,10}$/, e.target.value)
						}
					/>
				</div>
				<div className='my-3'>
					<label htmlFor='emailInput' className='form-label'>
						Email Address
					</label>
					<input
						type='email'
						id='emailInput'
						name='email'
						className='form-control'
						placeholder='example@gmail.com'
					/>
				</div>
				<div className='my-3'>
					<label htmlFor='districtSelect' className='form-label'>
						Town / City<b style={{ color: 'red' }}>*</b>
					</label>
					<select
						id='districtSelect'
						value={selectedDistrict}
						onChange={handleChange}
						className='form-select'
					>
						<option value=''>Select a district</option>
						{districtOptions.map((district) => (
							<option key={district} value={district}>
								{district}
							</option>
						))}
					</select>
				</div>
				<div>
					<label htmlFor='stateSelect' className='form-label'>
						Select State<b style={{ color: 'red' }}>*</b>
					</label>
					<select
						id='stateSelect'
						className='form-select'
						aria-label='Default select example'
					>
						<option selected>Select State</option>
						<option value='Bihar'>Bihar</option>
					</select>
				</div>
				<div className='form-check my-3'>
					<input
						className='form-check-input'
						type='checkbox'
						value=''
						id='flexCheckIndeterminate'
					/>
					<label className='form-check-label' htmlFor='flexCheckIndeterminate'>
						I agree to SunRuf - Switch To Solar's{' '}
						<a href='!#' style={{ color: '#003056' }}>
							terms of service
						</a>{' '}
						&{' '}
						<a href='!#' style={{ color: '#003056' }}>
							privacy policy.
						</a>
					</label>
				</div>
				<p className='my-3 text-muted text-center'>
					<button
						className='p-2 w-50'
						style={{ background: '#003056', color: 'white' }}
					>
						Submit
					</button>
				</p>
			</div>
		</div>
	);
};

export default SolarEntrepreneur;
