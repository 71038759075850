import React from 'react';
import Marquee from 'react-fast-marquee';

import img0 from '../assets/marquee/2.png';
import img1 from '../assets/marquee/3.webp';
import img2 from '../assets/marquee/10.png';
import img3 from '../assets/marquee/8.png';
import img4 from '../assets/marquee/11.png';
import img5 from '../assets/marquee/0.png';
import img6 from '../assets/marquee/9.png';
import img7 from '../assets/marquee/12.png';
import img8 from '../assets/marquee/19.png';

import HeadingComponent from './headingComponent/HeadingComponent';

const Marquee_Images = [img0, img1, img2, img3, img4, img5, img6, img7, img8];

const MarQuee = () => {
	return (
		<div className='container-fluid marquee_container '>
			<HeadingComponent headerName={'Our Product Partners'} />
			<div>
				<Marquee play={true} speed={200}>
					{Marquee_Images.map((marquee, index) => (
						<div key={index} className='image-wrapper bg-white'>
							<img src={marquee} alt='' />
						</div>
					))}
				</Marquee>
			</div>
		</div>
	);
};

export default MarQuee;
